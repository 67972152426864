import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';
import { BaseTileLayers, WebMapConfig, WMSLayers } from '@/configs/map.config';
import { LatLng } from '@/types';
import { TileLayerConfig, WMSLayer } from '@/types/map';

class MapState {
  isLoading = false;

  zoomLevel: number = WebMapConfig.defaultZoom;

  center: LatLng = WebMapConfig.defaultCenter;

  baseTileLayers: TileLayerConfig[] = BaseTileLayers;

  wmsLayers: WMSLayer[] = WMSLayers;

  lastActivatedWmsLayers: string[] = [];
}

class MapGetters extends Getters<MapState> {}

class MapMutations extends Mutations<MapState> {
  updateLoadingState(payload: boolean) {
    this.state.isLoading = payload;
  }

  updateZoomLevel(payload: number) {
    this.state.zoomLevel = payload;
  }

  updateCenter(payload: LatLng) {
    this.state.center = payload;
  }

  updateLastActivatedWmsLayers(layers: string[]) {
    this.state.lastActivatedWmsLayers = layers;
  }

  toggleBaseTileLayer(layerName: string) {
    this.state.baseTileLayers = this.state.baseTileLayers.map((layer) => ({
      ...layer,
      visible: layer.name === layerName,
    }));
  }

  toggleWMSTileLayer(layerName: string) {
    let { wmsLayers } = this.state;
    const target = wmsLayers.find(({ layers }) => layers === layerName);
    if (target) {
      target.visible = !target.visible;
      wmsLayers = wmsLayers.slice(0); // trigger changes
    }
  }

  enableAllWMSTileLayerVisibleTemporarily() {
    let { wmsLayers } = this.state;
    const visbleLayerNames = wmsLayers
      .filter(({ visible }) => visible === true)
      .map(({ name }) => name);
    this.state.lastActivatedWmsLayers = visbleLayerNames;
    wmsLayers.forEach((v) => {
      v.visible = true;
    });
    wmsLayers = wmsLayers.slice(0); // trigger changes
  }

  restoreWMSTileLayerVisible() {
    let { wmsLayers, lastActivatedWmsLayers } = this.state;
    wmsLayers.forEach((v) => {
      v.visible = lastActivatedWmsLayers.includes(v.name);
    });
    wmsLayers = wmsLayers.slice(0); // trigger changes
    lastActivatedWmsLayers = [];
  }
}

class MapActions extends Actions<MapState, MapGetters, MapMutations, MapActions> {}

export const mapModule = new Module({
  state: MapState,
  getters: MapGetters,
  mutations: MapMutations,
  actions: MapActions,
});

export const mapModuleMapper = createMapper(mapModule);
