import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';
import { ToastPayload, ToastProperties } from '@/types/toast';

class ToastState {
  isActive = false;

  message = '';

  color = '';

  timeout = -1;
}

class ToastGetters extends Getters<ToastState> {}

class ToastMutations extends Mutations<ToastState> {
  setToast(payload: ToastProperties) {
    this.state.isActive = payload.isActive || false;
    this.state.message = payload.message || '';
    this.state.color = payload.color || '';
    this.state.timeout = payload.timeout || -1;
  }

  resetToast() {
    this.state.isActive = false;
    this.state.message = '';
    this.state.color = '';
    this.state.timeout = -1;
  }

  updateIsActive(payload: boolean) {
    this.state.isActive = payload;
  }
}

class ToastActions extends Actions<ToastState, ToastGetters, ToastMutations, ToastActions> {
  openToast(payload: ToastPayload) {
    this.mutations.updateIsActive(false);
    setTimeout(() => {
      this.mutations.setToast({
        isActive: true,
        color: payload.color || 'info',
        message: payload.message,
        timeout: payload.timeout || 5000,
      });
    }, 100);
  }

  openErrorToast(payload: ToastPayload) {
    this.mutations.updateIsActive(false);
    setTimeout(() => {
      this.mutations.setToast({
        isActive: true,
        color: 'error',
        message: payload.message,
        timeout: payload.timeout || 6000,
      });
    }, 100);
  }

  closeToast() {
    this.mutations.updateIsActive(false);
    setTimeout(() => {
      this.mutations.resetToast();
    }, 100);
  }
}

export const toastModule = new Module({
  state: ToastState,
  getters: ToastGetters,
  mutations: ToastMutations,
  actions: ToastActions,
});

export const toastModuleMapper = createMapper(toastModule);
