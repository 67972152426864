



































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment';
import RouteSaveDialog from '@/components/panel/RouteSaveDialog.vue';
import RouteDeleteDialog from '@/components/panel/RouteDeleteDialog.vue';
import { routeModule, routeModuleMapper } from '@/store/modules/route';
import {
  AvoidLocation,
  RouteErrorResponse,
  RouteRestrictionTypes,
  RouteResult,
  RouteSaveRequest,
  RouteSegment,
  RouteStatus,
} from '@/types/route';
import { RouteDirectionTypes, RouteStatusType, RouteStatusColor } from '@/configs/route.config';
import ApiService from '@/services/api';
import { toastModule } from '@/store/modules/toast';
import { ConfigItem } from '@/types/app';
import ConfigDifferenceDialog from './ConfigDifferenceDialog.vue';
import { DateUtils } from '@/utils';
import { routeListModule, routeListModuleMapper } from '@/store/modules/route-list';

@Component({
  components: {
    RouteSaveDialog,
    RouteDeleteDialog,
    ConfigDifferenceDialog,
  },
  computed: {
    ...routeModuleMapper.mapState([
      'isLoading',
      'isGeneratedRoute',
      'selectedSegmentIndex',
      'currentConfigs',
      'routeErrorResponse',
    ]),
    ...routeModuleMapper.mapGetters([
      'routeSegments',
      'routeResult',
      'routeStatus',
      'isConfirmedRoute',
      'currentIRNVersion',
    ]),
    ...routeListModuleMapper.mapState(['isRedirectFromRouteList']),
  },
})
export default class SearchResult extends Vue {
  readonly routeModuleContext = routeModule.context(this.$store);

  readonly routeListModuleContext = routeListModule.context(this.$store);

  readonly toastModuleContext = toastModule.context(this.$store);

  isRedirectFromRouteList!: boolean;

  isLoading!: boolean;

  isGeneratedRoute!: boolean;

  currentIRNVersion!: string;

  currentConfigs!: ConfigItem[];

  routeErrorResponse!: RouteErrorResponse | null;

  routeResult!: RouteResult | null;

  routeStatus!: RouteStatus;

  isConfirmedRoute!: boolean;

  routeSegments!: RouteSegment[];

  selectedSegmentIndex!: number | null;

  routeDirectionMap: Map<number, string> = RouteDirectionTypes;

  routeStatusType = RouteStatusType;

  routeStatusColor = RouteStatusColor;

  showSaveDialog = false;

  showDeleteDialog = false;

  showConfigDifferenceDialog = false;

  get irnVersionUsed(): string {
    return DateUtils.getDateFromISOString(this.routeStatus.irnVersion ?? '');
  }

  get isIRNVersionDifferent(): boolean {
    return (
      this.currentIRNVersion !== '' &&
      this.irnVersionUsed !== '' &&
      this.currentIRNVersion !== this.irnVersionUsed
    );
  }

  get isConfigsDifferent(): boolean {
    const current = JSON.stringify(this.currentConfigs);
    const used = JSON.stringify(this.routeStatus.configUsed ?? []);
    return current !== used;
  }

  get lastUpdateOn(): string {
    return DateUtils.getDateFromISOString(this.routeStatus.lastUpdateOn ?? '');
  }

  get isPrevBtnDisabled(): boolean {
    return this.selectedSegmentIndex === null || this.selectedSegmentIndex === 0;
  }

  get isNextBtnDisabled(): boolean {
    return this.selectedSegmentIndex === this.routeSegments.length - 1;
  }

  get prevSegmentIndex(): number | null {
    const currentIdx = this.selectedSegmentIndex;
    if (currentIdx !== null && currentIdx > 0) return currentIdx - 1;
    return null;
  }

  get nextSegmentIndex(): number | null {
    const currentIdx = this.selectedSegmentIndex;
    if (currentIdx === null) return 0;
    if (currentIdx < this.routeSegments.length - 1) return currentIdx + 1;
    return null;
  }

  get etaText(): string {
    const eta = this.routeResult?.route.time;
    if (eta) {
      const mm = moment(eta, 'hh:mm:ss');
      const totalMins = mm.hour() * 60 + mm.minute() + (mm.second() > 30 ? 1 : 0);
      return totalMins > 0 ? `${totalMins} 分鐘` : `少於 1 分鐘`;
    }
    return '未知';
  }

  scrollToSegment(segmentIndex: number): void {
    const container = document.querySelector('#route-result-table-container') as HTMLElement;
    const header = document.querySelector('#route-result-table thead') as HTMLElement;
    const item = document.querySelector(`#segment-table-row-${segmentIndex}`) as HTMLElement;
    container.scrollTop = item.offsetTop - header.offsetHeight;
  }

  selectSegment(index: number, scroll = false): void {
    // Update selected segment index
    const idx = this.selectedSegmentIndex !== index ? index : null;
    this.routeModuleContext.mutations.updateSelectedSegmentIndex(idx);
    // Scroll to table row if needed
    if (scroll) {
      this.scrollToSegment(index);
    }
  }

  updateSegmentRemark(index: number, remark: string): void {
    this.routeModuleContext.mutations.updateSegmentRemark({ index, remark });
  }

  getAllRestrictionText(segment: RouteSegment): string {
    const descList = RouteRestrictionTypes.flatMap((t) =>
      (segment[t] ?? []).flatMap((r) => `<li>${r.desc}</li>`),
    );
    if (segment.ra && segment.ra.length > 0) {
      descList.push('<li>駛經迴旋處</li>');
    }
    return descList.length > 0 ? `<ul style="padding-top: 3px">${descList.join('')}</ul>` : '';
  }

  getAllWarningText(segment: RouteSegment): string {
    const descList =
      segment?.warnMsg?.flatMap(
        (r) =>
          `<li><label class="font-weight-bold red--text">${r?.desc ?? ''}</label>${
            r?.reminder ?? ''
          }</li>`,
      ) ?? [];
    return descList.length > 0 ? `<ul style="padding-top: 3px">${descList.join('')}</ul>` : '';
  }

  getSpeedLimit(segment: RouteSegment): number {
    const map = segment.rid.map((a) => {
      return segment.rid.filter((b) => {
        return a === b;
      }).length;
    });

    return segment.rid[map.indexOf(Math.max.apply(null, map))][1];
  }

  async getRoute(): Promise<void> {
    await this.routeModuleContext.actions.getRoute();
  }

  async generateRoute(): Promise<void> {
    await this.routeModuleContext.actions.generateRoute();
  }

  async addAvoidLocation(segment: RouteSegment): Promise<void> {
    const { coordinates } = segment.shape;
    if (coordinates.length === 2 || coordinates.length === 3) {
      // If number of points = 2, then avoid the starting point
      // If number of points = 3, then avoid the middle point
      const coord = coordinates.length === 2 ? coordinates[0] : coordinates[1];
      const avoidLatLng = { lat: coord[0], long: coord[1], isDefault: false } as AvoidLocation;
      this.routeModuleContext.mutations.addAvoidLocation(avoidLatLng);
    } else {
      // If number of points > 3, then avoid the second point and the second to last point
      const coordA = coordinates[1];
      const coordB = coordinates[coordinates.length - 2];
      const avoidLatLngA = { lat: coordA[0], long: coordA[1], isDefault: false } as AvoidLocation;
      const avoidLatLngB = { lat: coordB[0], long: coordB[1], isDefault: false } as AvoidLocation;
      this.routeModuleContext.mutations.addAvoidLocation(avoidLatLngA);
      this.routeModuleContext.mutations.addAvoidLocation(avoidLatLngB);
    }
    await this.routeModuleContext.actions.generateRoute();
  }

  async save(confirm: boolean): Promise<void> {
    try {
      this.routeModuleContext.mutations.updateSavingState(true);
      const payload: RouteSaveRequest = {
        pairID: this.routeResult!.pairID,
        result: JSON.stringify(this.routeResult),
        irnVersion: this.routeStatus.irnVersion ?? '',
        configUsed: JSON.stringify(this.currentConfigs),
        confirm: confirm ? 1 : 0,
      };
      await ApiService.saveRoute(payload);
      this.toastModuleContext.actions.openToast({
        color: 'success',
        message: '成功儲存',
      });
      this.routeModuleContext.actions.getRoute();
      // Go back route list page if redirect state is true
      if (this.isRedirectFromRouteList) {
        this.routeListModuleContext.mutations.updateRedirectState(false);
        this.$router.push('/');
      }
    } catch (error) {
      this.toastModuleContext.actions.openErrorToast({ message: '儲存時發生錯誤' });
    } finally {
      this.routeModuleContext.mutations.updateSavingState(false);
    }
  }

  async deleteRoute(): Promise<void> {
    try {
      this.routeModuleContext.mutations.updateSavingState(true);
      await ApiService.deleteRoute(this.routeResult!.pairID);
      this.toastModuleContext.actions.openToast({
        color: 'success',
        message: '成功刪除',
      });
      this.routeModuleContext.actions.clearRoute();
      this.routeModuleContext.actions.clearLocation();
      // Go back route list page if redirect state is true
      if (this.isRedirectFromRouteList) {
        this.routeListModuleContext.mutations.updateRedirectState(false);
        this.$router.push('/');
      }
    } catch (error) {
      this.toastModuleContext.actions.openErrorToast({ message: '刪除時發生錯誤' });
    } finally {
      this.routeModuleContext.mutations.updateSavingState(false);
    }
  }
}
