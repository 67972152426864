


























































import { Vue, Component } from 'vue-property-decorator';
import { LMap, LTileLayer, LWMSTileLayer, LControlAttribution, LControlScale } from 'vue2-leaflet';
import { Map as LeafletMap } from 'leaflet';
import {
  WebMapConfig,
  WebMapPaneConfig,
  LabelTileLayer,
  LayerServiceURL,
} from '@/configs/map.config';
import { mapModule, mapModuleMapper } from '@/store/modules/map';
import WmsNonTiledLayer from '@/components/leaflet/WMSNonTiledLayer.vue';
import RouteLayer from '@/components/map/layers/RouteLayer.vue';
import MapControls from '@/components/map/layers/MapControls.vue';
import DefaultAvoidLocationControls from '@/components/map/layers/DefaultAvoidLocationControls.vue';
import DrawLayer from './layers/DrawLayer.vue';
import { MapConfig, TileLayerConfig, WMSLayer } from '@/types/map';
import { LatLng } from '@/types';

@Component({
  components: {
    LMap,
    LTileLayer,
    'l-wms-tile-layer': LWMSTileLayer,
    LControlAttribution,
    LControlScale,
    RouteLayer,
    MapControls,
    WmsNonTiledLayer,
    DrawLayer,
    DefaultAvoidLocationControls,
  },
  computed: {
    ...mapModuleMapper.mapState([
      'isLoading',
      'zoomLevel',
      'center',
      'baseTileLayers',
      'wmsLayers',
    ]),
  },
})
export default class WebMap extends Vue {
  readonly mapModuleContext = mapModule.context(this.$store);

  $refs!: {
    mapRef: LMap;
  };

  map = {} as LeafletMap;

  isLoading!: boolean;

  zoomLevel!: number;

  center!: LatLng;

  baseTileLayers!: TileLayerConfig[];

  wmsLayers!: WMSLayer[];

  mapConfig: MapConfig = WebMapConfig;

  layerServiceURL: string = LayerServiceURL;

  labelTileLayer: TileLayerConfig = LabelTileLayer;

  leafletOptions = {
    attributionControl: false,
  };

  get mapZoom(): number {
    return this.zoomLevel;
  }

  set mapZoom(newLevel: number) {
    if (this.zoomLevel !== newLevel) {
      this.mapModuleContext.mutations.updateZoomLevel(newLevel);
    }
  }

  get mapCenter(): LatLng {
    return this.center;
  }

  set mapCenter(newCenter: LatLng) {
    if (this.center !== newCenter) {
      this.mapModuleContext.mutations.updateCenter(newCenter);
    }
  }

  toggleBaseLayer(layerName: string): void {
    this.mapModuleContext.mutations.toggleBaseTileLayer(layerName);
  }

  toggleWMSLayer(layerName: string): void {
    this.mapModuleContext.mutations.toggleWMSTileLayer(layerName);
  }

  mounted(): void {
    this.map = this.$refs.mapRef.mapObject;
    // create map panes and set css style
    Object.values(WebMapPaneConfig).forEach((config) => {
      const createdPane = this.map.createPane(config.pane);
      Object.assign(createdPane.style, config.style);
    });
  }
}
