









import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Map as LeafletMap, Polygon } from 'leaflet';
import { LGeoJson } from 'vue2-leaflet';
import { Feature } from 'geojson';
import { routeListModule, routeListModuleMapper } from '@/store/modules/route-list';
import { routeModule } from '@/store/modules/route';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

@Component({
  components: {
    LGeoJson,
  },
  computed: {
    ...routeListModuleMapper.mapState(['selectedArea']),
  },
})
export default class DrawLayer extends Vue {
  @Prop() map!: LeafletMap;

  readonly routeListModuleContext = routeListModule.context(this.$store);

  readonly routeModuleContext = routeModule.context(this.$store);

  selectedArea!: Feature | null;

  setDrawingTools(enableDraw: boolean): void {
    this.map.pm.removeControls();
    this.map.pm.addControls({
      position: 'topleft',
      customControls: !enableDraw, // clear button
      drawPolygon: enableDraw,
      drawMarker: false,
      drawCircleMarker: false,
      drawPolyline: false,
      drawRectangle: false,
      drawCircle: false,
      editControls: false,
    });
  }

  updateDrawing(geoJSON: Feature): void {
    this.routeListModuleContext.mutations.resetState();
    this.routeListModuleContext.mutations.updateSelectedArea(geoJSON);
    this.$router.push('/');
  }

  clearDrawing(): void {
    this.routeListModuleContext.mutations.resetState();
    this.setDrawingTools(true);
  }

  mounted(): void {
    this.map.pm.setLang('zh_tw');

    this.map.pm.Toolbar.createCustomControl({
      name: 'clear',
      block: 'custom',
      title: '取消已選範圍',
      className: 'leaflet-pm-icon-delete',
      toggle: false,
      onClick: this.clearDrawing,
    });

    this.setDrawingTools(this.selectedArea === null);

    this.map.pm.setGlobalOptions({
      ...this.map.pm.getGlobalOptions(),
      templineStyle: { color: 'red' },
      hintlineStyle: { color: 'red', dashArray: [5, 5] },
    });

    this.map.on('pm:create', async (e) => {
      if (e.layer instanceof Polygon) {
        const geoJSON = e.layer.toGeoJSON();
        e.layer.remove();
        this.updateDrawing(geoJSON);
      }
    });
    this.map.on('pm:globaldrawmodetoggled', (evt) => {
      if (evt.enabled && this.routeModuleContext.state.isEnabledDefaultAvoidControls) {
        this.map.pm.disableDraw();
        // this.routeModuleContext.commit('updateDefaultAvoidControlsDisplay', false);
      }
    });
  }

  @Watch('routeModuleContext.state.isEnabledDefaultAvoidControls')
  onChangeIsEnabledDefaultAvoidControls(val: boolean): void {
    if (val === true) {
      this.map.pm.disableDraw();
    }
  }
}
