









import { Vue, Component, Prop } from 'vue-property-decorator';
import { Feature, Point } from 'geojson';
import PopupTemplate from '@/components/map/popups/PopupTemplate.vue';
import { RouteRestrictionType } from '@/types/route';
import { RouteRestrictionDescription } from '@/configs/route.config';
import { WebMapColorConfig } from '@/configs/map.config';
import { MapColorSetting } from '@/types/map';

@Component({
  components: {
    PopupTemplate,
  },
})
export default class RouteRestrictionPopup extends Vue {
  @Prop({ required: true, type: Object }) feature!: Feature<Point>;

  colorConfig: MapColorSetting = WebMapColorConfig.marker;

  get restrictionType(): RouteRestrictionType | undefined {
    return this.feature.properties?.type;
  }

  get restrictionDesc(): string {
    return this.feature.properties?.desc || '';
  }

  get restrictionTitle(): string {
    if (this.restrictionType) {
      return RouteRestrictionDescription.get(this.restrictionType) || '';
    }
    return '';
  }

  get headerColor(): string {
    return this.restrictionType !== 'ped'
      ? this.colorConfig.restriction
      : this.colorConfig.restrictionPed;
  }
}
