














































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { DataOptions, DataTableHeader } from 'vuetify';
import Lodash from 'lodash';
import { Feature } from 'geojson';
import ApiService from '@/services/api';
import { RouteBasicInfo, RouteListFilter, RouteListResponse } from '@/types/route';
import { DateUtils } from '@/utils';
import { routeModule } from '@/store/modules/route';
import { toastModule } from '@/store/modules/toast';
import { RouteStatusType, RouteStatusColor } from '@/configs/route.config';
import { ProjectOptions } from '@/configs/project.config';
import { routeListModule, routeListModuleMapper } from '@/store/modules/route-list';
import RouteDeleteDialog from '@/components/panel/RouteDeleteDialog.vue';

interface RouteListTableRow {
  originLocationID: string;
  originName: string;
  originType: string;
  originProject: string;
  destinationLocationID: string;
  destinationName: string;
  destinationType: string;
  irnVersion: string;
  createdOn: string;
  lastUpdateOn: string;
  status: string;
  rawData: RouteBasicInfo;
}

@Component({
  components: {
    RouteDeleteDialog,
  },
  computed: {
    ...routeListModuleMapper.mapState(['filter', 'dataOptions', 'displayPage', 'selectedArea']),
  },
})
export default class RouteList extends Vue {
  readonly routeModuleContext = routeModule.context(this.$store);

  readonly routeListModuleContext = routeListModule.context(this.$store);

  readonly toastModuleContext = toastModule.context(this.$store);

  routeStatusType = RouteStatusType;

  routeStatusColor = RouteStatusColor;

  windowSize = { x: 0, y: 0 };

  isLoading = false;

  createdOnDatePicker = false;

  lastUpdateDatePicker = false;

  showDeleteDialog = false;

  pendingDeleteRoute: RouteListTableRow | null = null;

  irnVersionList: string[] = [];

  oriProjectList: string[] = [];

  statusList: string[] = ['', ...Object.values(this.routeStatusType)];

  data: RouteListTableRow[] = [];

  filter!: RouteListFilter;

  dataOptions!: DataOptions;

  displayPage!: number;

  selectedArea!: Feature | null;

  isExporting = false;

  get tableHeight(): number {
    const windowHeight = this.windowSize.y;
    const appHeaderHeight = 60;
    const tableFooterHeight = 59;
    const selectedAreaAlertHeight = this.selectedArea !== null ? 24 : 0;
    return windowHeight - appHeaderHeight - tableFooterHeight - selectedAreaAlertHeight;
  }

  get tableDataOptions(): DataOptions {
    return this.dataOptions;
  }

  set tableDataOptions(val: DataOptions) {
    this.routeListModuleContext.mutations.updateDataOptions(val);
  }

  get headers(): DataTableHeader[] {
    return [
      {
        text: '項目',
        value: 'originProject',
        filter: (value: string): boolean => this.filterText(value, this.filter.originProject),
        align: 'center',
        width: 130,
      },
      {
        text: '地點編號',
        value: 'originLocationID',
        filter: (value: string): boolean => this.filterText(value, this.filter.originLocationID),
        align: 'center',
        width: 100,
      },
      {
        text: '客戶名稱',
        value: 'originName',
        filter: (value: string): boolean => this.filterText(value, this.filter.originName),
        align: 'center',
        width: 150,
      },
      {
        text: '客戶種類',
        value: 'originType',
        filter: (value: string): boolean => this.filterText(value, this.filter.originType),
        align: 'center',
        width: 150,
      },
      {
        text: '地點編號',
        value: 'destinationLocationID',
        filter: (value: string): boolean =>
          this.filterText(value, this.filter.destinationLocationID),
        align: 'center',
        width: 100,
      },
      {
        text: '客戶名稱',
        value: 'destinationName',
        filter: (value: string): boolean => this.filterText(value, this.filter.destinationName),
        align: 'center',
        width: 150,
      },
      {
        text: '客戶種類',
        value: 'destinationType',
        filter: (value: string): boolean => this.filterText(value, this.filter.destinationType),
        align: 'center',
        width: 150,
      },
      {
        text: '智能道路網版本',
        value: 'irnVersion',
        filter: (value: string): boolean => this.filterText(value, this.filter.irnVersion),
        align: 'center',
        width: 135,
      },
      {
        text: '建立日期',
        value: 'createdOn',
        filter: (value: string): boolean => this.filterText(value, this.filter.createdOn),
        align: 'center',
        width: 135,
      },
      {
        text: '最後更新日期',
        value: 'lastUpdateOn',
        filter: (value: string): boolean => this.filterText(value, this.filter.lastUpdateOn),
        align: 'center',
        width: 135,
      },
      {
        text: '狀態',
        value: 'status',
        filter: (value: string): boolean => this.filterStatus(value, this.filter.routeStatus),
        align: 'center',
        width: 135,
      },
      {
        text: '刪除',
        value: 'actions',
        align: 'center',
        width: 60,
        sortable: false,
      },
    ];
  }

  filterText(value: string, input: string): boolean {
    return value.toLowerCase().includes((input ?? '').toLowerCase());
  }

  filterStatus(value: string, input: string): boolean {
    switch (input) {
      case this.routeStatusType.Confirmed:
        return value === this.routeStatusType.Confirmed;
      case this.routeStatusType.Pending:
        return value === this.routeStatusType.Pending;
      case this.routeStatusType.Error:
        return value !== this.routeStatusType.Confirmed && value !== this.routeStatusType.Pending;
      default:
        return true;
    }
  }

  updateFilter(key: keyof RouteListFilter, value: string | null): void {
    this.routeListModuleContext.mutations.updateFilter({ key, value: value ?? '' });
  }

  getStatusTextColor(status: string): string {
    switch (status) {
      case this.routeStatusType.Confirmed:
      case this.routeStatusType.Pending:
        return this.routeStatusColor[status];
      default:
        return this.routeStatusColor[this.routeStatusType.Error];
    }
  }

  getProjectName(projectCode: string): string {
    const projectName = ProjectOptions.find((v) => v.value === projectCode)?.text;
    return projectName ?? projectCode;
  }

  async route(row: RouteListTableRow): Promise<void> {
    this.routeListModuleContext.mutations.updateRedirectState(true);
    this.routeModuleContext.actions.clearLocation();
    this.routeModuleContext.mutations.updateStartLocation(row.rawData.origin);
    this.routeModuleContext.mutations.updateEndLocation(row.rawData.destination);
    this.routeModuleContext.actions.getRoute();
    this.$router.push('/route');
  }

  deleteRoute(row: RouteListTableRow): void {
    this.pendingDeleteRoute = row;
    this.showDeleteDialog = true;
  }

  async confirmDeleteRoute(): Promise<void> {
    if (this.pendingDeleteRoute) {
      try {
        // Delete route
        await ApiService.deleteRoute(this.pendingDeleteRoute.rawData.pairID);
        this.toastModuleContext.actions.openToast({
          color: 'success',
          message: '成功刪除',
        });
        this.routeModuleContext.actions.clearRoute();
        this.routeModuleContext.actions.clearLocation();
        // Clear list and fetch from database again
        this.isLoading = true;
        this.data = [];
        await this.getRouteList();
      } catch (error) {
        this.toastModuleContext.actions.openErrorToast({ message: '刪除時發生錯誤' });
      } finally {
        this.isLoading = false;
      }
    }
  }

  async exportRouteList(): Promise<void> {
    try {
      this.isExporting = true;
      await ApiService.exportRouteList();
    } finally {
      this.isExporting = false;
    }
  }

  onResize(): void {
    this.windowSize = { x: window.innerWidth, y: window.innerHeight };
  }

  convertToTableRow(data: RouteBasicInfo): RouteListTableRow {
    return {
      originLocationID: data.origin.locationId,
      originName: data.origin.name,
      originType: data.origin.type ?? '',
      originProject: data.origin.project,
      destinationLocationID: data.destination.locationId,
      destinationName: data.destination.name,
      destinationType: data.destination.type ?? '',
      irnVersion: DateUtils.getDateFromISOString(data.irnVersion),
      createdOn: DateUtils.getDateFromISOString(data.createdOn),
      lastUpdateOn: DateUtils.getDateFromISOString(data.lastUpdateOn),
      status: data.confirmedOn
        ? this.routeStatusType.Confirmed
        : data.errorMessage ?? this.routeStatusType.Pending,
      rawData: data,
    };
  }

  extractSelectOptions(): void {
    this.irnVersionList = ['', ...Lodash.uniq(this.data.map((o) => o.irnVersion))];
    this.oriProjectList = ['', ...Lodash.uniq(this.data.map((o) => o.originProject))];
  }

  addTopLevelHeader(): void {
    // Get original header element
    const originalHeader = document.querySelector('.v-data-table-header') as HTMLElement;

    // Create new table header element
    const tableHeader = document.createElement('thead');
    const tableRow = document.createElement('tr');
    tableHeader.appendChild(tableRow);
    tableRow.className = 'data-table-header-top-level';

    // Create and apped new table column element
    const columns = ['起點', '終點', '路線'];
    columns.forEach((text, colIdx) => {
      const tableColumn = document.createElement('th');
      tableColumn.innerText = text;
      tableColumn.setAttribute('colspan', colIdx === 1 ? '3' : '4');
      tableRow.appendChild(tableColumn);
    });
    const actionColumn = document.createElement('th');
    tableRow.appendChild(actionColumn);

    // Insert the new header before original header
    if (originalHeader.parentNode) {
      originalHeader.parentNode.insertBefore(tableHeader, originalHeader);
    }
  }

  async addFooterInfo(): Promise<void> {
    // Get data from API
    let irnLatestVersion = '';
    let stationUploadTime = '';
    let roadHazardUploadTime = '';
    try {
      irnLatestVersion = await ApiService.getIRNVersion();
      irnLatestVersion = DateUtils.getDateFromISOString(irnLatestVersion);
    } catch {
      irnLatestVersion = 'UNKNOWN';
    }
    try {
      stationUploadTime = await ApiService.getSGSLocationSyncTime();
      stationUploadTime = DateUtils.getDateTimeFromISOString(stationUploadTime);
    } catch {
      stationUploadTime = 'UNKNOWN';
    }
    try {
      roadHazardUploadTime = await ApiService.getRoadHazardUploadTime();
      roadHazardUploadTime = DateUtils.getDateTimeFromISOString(roadHazardUploadTime);
    } catch {
      roadHazardUploadTime = 'UNKNOWN';
    }

    const table = document.createElement('table');
    // IRN
    const tableFirstRow = document.createElement('tr');
    const tableFirstRowHeader = document.createElement('th');
    const tableFirstRowValue = document.createElement('td');
    tableFirstRowHeader.innerText = 'IRN 最新版本:';
    tableFirstRowValue.innerText = irnLatestVersion;
    table.appendChild(tableFirstRow);
    tableFirstRow.appendChild(tableFirstRowHeader);
    tableFirstRow.appendChild(tableFirstRowValue);

    // Station Database
    const tableSecondRow = document.createElement('tr');
    const tableSecondRowHeader = document.createElement('th');
    const tableSecondRowValue = document.createElement('td');
    tableSecondRowHeader.innerText = 'Station Database:';
    tableSecondRowValue.innerText = stationUploadTime;
    table.appendChild(tableSecondRow);
    tableSecondRow.appendChild(tableSecondRowHeader);
    tableSecondRow.appendChild(tableSecondRowValue);

    // Road Hazard Database
    const tableThirdRow = document.createElement('tr');
    const tableThirdRowHeader = document.createElement('th');
    const tableThirdRowValue = document.createElement('td');
    tableThirdRowHeader.innerText = 'Road Hazard Database:';
    tableThirdRowValue.innerText = roadHazardUploadTime;
    table.appendChild(tableThirdRow);
    tableThirdRow.appendChild(tableThirdRowHeader);
    tableThirdRow.appendChild(tableThirdRowValue);

    // Insert elements to footer
    const infoDiv = document.createElement('div');
    infoDiv.className = 'data-table-footer-info';
    infoDiv.append(table);
    const footer = document.querySelector('.v-data-footer') as HTMLElement;
    if (footer) {
      footer.insertBefore(infoDiv, footer.children[0]);
    }
  }

  async getRouteList(): Promise<void> {
    let response: RouteListResponse;
    if (this.selectedArea && this.selectedArea.geometry.type === 'Polygon') {
      const { coordinates } = this.selectedArea.geometry;
      response = await ApiService.getRouteListByArea({ coordinates });
    } else {
      response = await ApiService.getRouteList();
    }
    this.data = response.map((o) => this.convertToTableRow(o));
    this.extractSelectOptions();
    this.tableDataOptions = { ...this.tableDataOptions, page: this.displayPage };
  }

  async mounted(): Promise<void> {
    this.onResize();
    try {
      this.isLoading = true;
      this.addTopLevelHeader();
      await this.getRouteList();
      this.isLoading = false;
      await this.addFooterInfo();
    } catch (error) {
      const message = typeof error === 'string' ? error : '發生未知的錯誤';
      this.toastModuleContext.actions.openErrorToast({ message });
      this.isLoading = false;
    }
  }

  destroyed(): void {
    this.routeListModuleContext.mutations.updateDisplayPage(this.dataOptions.page);
  }
}
