
















































import { Vue, Component, Watch } from 'vue-property-decorator';
import SearchTextField from '@/components/panel/SearchTextField.vue';
import PinMarker from '@/components/map/markers/PinMarker.vue';
import { routeModule, routeModuleMapper } from '@/store/modules/route';
import {
  DestinationSuggestionRequest,
  OriginSuggestionRequest,
  RouteListFilter,
  SGSLocation,
} from '@/types/route';
import ApiService from '@/services/api';
import { routeListModule, routeListModuleMapper } from '@/store/modules/route-list';
import { MapColorSetting } from '@/types/map';
import { WebMapColorConfig } from '@/configs/map.config';
import { ProjectOptionsWithSelectAll } from '@/configs/project.config';

@Component({
  components: {
    SearchTextField,
    PinMarker,
  },
  computed: {
    ...routeListModuleMapper.mapState(['filter']),
    ...routeModuleMapper.mapState(['isLoading', 'startLocation', 'endLocation']),
  },
})
export default class SearchBar extends Vue {
  $refs!: {
    endLocationTextFieldRef: SearchTextField;
    startLocationTextFieldRef: SearchTextField;
  };

  readonly routeModuleContext = routeModule.context(this.$store);

  readonly routeListModuleContext = routeListModule.context(this.$store);

  isLoading!: boolean;

  startLocation!: SGSLocation | null;

  endLocation!: SGSLocation | null;

  startLocationSuggestions: SGSLocation[] = [];

  endLocationSuggestions: SGSLocation[] = [];

  markerColorConfig: MapColorSetting = WebMapColorConfig.marker;

  projectOptions = ProjectOptionsWithSelectAll;

  filter!: RouteListFilter;

  get selectedProjectIndex(): number {
    const foundIndex = this.projectOptions.findIndex((v) => v.value === this.filter.originProject);
    return foundIndex > -1 ? foundIndex : 0;
  }

  set selectedProjectIndex(val: number) {
    this.routeListModuleContext.mutations.updateFilter({
      key: 'originProject',
      value: this.projectOptions[val].value ?? '',
    });
  }

  get selectedProjectValue(): string | null {
    return this.projectOptions[this.selectedProjectIndex].value;
  }

  get startLocationProject(): string | null {
    if (this.startLocation) {
      const idx = this.findProjectIndex(this.startLocation.project);
      return this.projectOptions[idx].value;
    }
    return null;
  }

  get endLocationProject(): string | null {
    if (this.endLocation) {
      const idx = this.findProjectIndex(this.endLocation.project);
      return this.projectOptions[idx].value;
    }
    return null;
  }

  get isSelectedBothLocation(): boolean {
    return this.startLocation !== null && this.endLocation !== null;
  }

  updateSelectedProject(idx: number): void {
    this.selectedProjectIndex = idx;
    this.routeModuleContext.actions.clearLocation();
  }

  findProjectIndex(projectName: string): number {
    const idx = this.projectOptions.findIndex((o) => o.value === projectName);
    return idx === -1 ? 0 : idx;
  }

  cancelRedirect(): void {
    this.routeListModuleContext.mutations.updateRedirectState(false);
  }

  async getRoute(): Promise<void> {
    await this.routeModuleContext.actions.getRoute();
  }

  async updateStartLocation(val: SGSLocation | null): Promise<void> {
    // Update store
    this.routeModuleContext.mutations.updateStartLocation(val);
    // Auto search if both start and end location is selected
    if (this.isSelectedBothLocation) {
      await this.getRoute();
    }
    // Cancel redirect to route list page
    this.cancelRedirect();
  }

  async updateEndLocation(val: SGSLocation | null): Promise<void> {
    // Update store
    this.routeModuleContext.mutations.updateEndLocation(val);
    // Auto search if both start and end location is selected
    if (this.isSelectedBothLocation) {
      await this.getRoute();
    }
    // Cancel redirect to route list page
    this.cancelRedirect();
  }

  async swapLocation(): Promise<void> {
    const { startLocation, endLocation } = this;
    this.routeModuleContext.mutations.updateStartLocation(endLocation);
    this.routeModuleContext.mutations.updateEndLocation(startLocation);
    await this.getRoute();
    // Cancel redirect to route list page
    this.cancelRedirect();
  }

  async getDestinationSuggestions(): Promise<void> {
    if (this.startLocation !== null) {
      const queryParams: DestinationSuggestionRequest = { oid: this.startLocation.id };
      if (this.selectedProjectValue) queryParams.project = this.selectedProjectValue;
      this.endLocationSuggestions = await ApiService.getDestinations(queryParams);
    } else {
      this.endLocationSuggestions = [];
    }
  }

  async getOriginSuggestions(): Promise<void> {
    if (this.endLocation !== null) {
      const queryParams: OriginSuggestionRequest = { did: this.endLocation.id };
      if (this.selectedProjectValue) queryParams.project = this.selectedProjectValue;
      this.startLocationSuggestions = await ApiService.getOrigins(queryParams);
    } else {
      this.startLocationSuggestions = [];
    }
  }

  @Watch('startLocation')
  async onStartLocationChange(): Promise<void> {
    // Get destination suggestion list
    this.getDestinationSuggestions();
    // Show suggestion list if end location is not selected
    if (this.startLocation !== null && this.endLocation === null) {
      this.$refs.endLocationTextFieldRef.focusTextField();
    }
  }

  @Watch('endLocation')
  async onEndLocationChange(): Promise<void> {
    // Get origin suggestion list
    this.getOriginSuggestions();
    // Show suggestion list if start location is not selected
    if (this.endLocation !== null && this.startLocation === null) {
      this.$refs.startLocationTextFieldRef.focusTextField();
    }
  }

  mounted(): void {
    this.getDestinationSuggestions();
    this.getOriginSuggestions();
  }
}
