import { Getters, Mutations, Actions, Module, createMapper } from 'vuex-smart-module';
import { DataOptions } from 'vuetify';
import { Feature } from 'geojson';
import { RouteListFilter } from '@/types/route';

class RouteListState {
  filter: RouteListFilter = {
    originLocationID: '',
    originName: '',
    originType: '',
    originProject: '',
    destinationLocationID: '',
    destinationName: '',
    destinationType: '',
    irnVersion: '',
    createdOn: '',
    lastUpdateOn: '',
    routeStatus: '',
  };

  dataOptions: DataOptions = {
    page: 1,
    itemsPerPage: 30,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };

  displayPage = 1;

  isRedirectFromRouteList = false;

  selectedArea: Feature | null = null;
}

class RouteListGetters extends Getters<RouteListState> {}

class RouteListMutations extends Mutations<RouteListState> {
  resetState() {
    Object.assign(this.state, new RouteListState());
  }

  updateFilter(payload: { key: keyof RouteListFilter; value: string }) {
    this.state.filter[payload.key] = payload.value;
  }

  updateDataOptions(payload: DataOptions) {
    this.state.dataOptions = payload;
  }

  updateRedirectState(payload: boolean) {
    this.state.isRedirectFromRouteList = payload;
  }

  updateDisplayPage(payload: number) {
    this.state.displayPage = payload;
  }

  updateSelectedArea(payload: Feature | null) {
    this.state.selectedArea = payload;
  }
}

class RouteListActions extends Actions<
  RouteListState,
  RouteListGetters,
  RouteListMutations,
  RouteListActions
> {}

export const routeListModule = new Module({
  state: RouteListState,
  getters: RouteListGetters,
  mutations: RouteListMutations,
  actions: RouteListActions,
});

export const routeListModuleMapper = createMapper(routeListModule);
