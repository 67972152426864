













import { Vue, Component } from 'vue-property-decorator';
import SearchBar from '@/components/panel/SearchBar.vue';
import SearchResult from '@/components/panel/SearchResult.vue';
import { routeModuleMapper } from '@/store/modules/route';

@Component({
  components: {
    SearchBar,
    SearchResult,
  },
  computed: {
    ...routeModuleMapper.mapState(['isSaving']),
  },
})
export default class WebPanel extends Vue {
  isSaving!: boolean;
}
