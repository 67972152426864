const SessionStorage = {
  set(name: string, value: string): boolean {
    try {
      window.sessionStorage.setItem(name, value);
      return true;
    } catch (error) {
      return false;
    }
  },
  get(name: string): string | null {
    return window.sessionStorage.getItem(name);
  },
  clear(name?: string): void {
    if (name != null) {
      window.sessionStorage.removeItem(name);
    } else {
      window.sessionStorage.clear();
    }
  },
};

export default SessionStorage;
