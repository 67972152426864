







import { Vue, Component } from 'vue-property-decorator';

@Component
export default class EnvLabel extends Vue {
  private envText = (process.env.VUE_APP_ENV || '').toUpperCase();

  get isDisplay(): boolean {
    return !!this.envText && ['TEST'].includes(this.envText);
  }
}
