


















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class RouteSaveDialog extends Vue {
  @Prop({ type: Boolean, default: false }) value!: boolean;

  @Prop({ type: Boolean, default: false }) confirm!: boolean;

  close(): void {
    this.$emit('input', false);
  }

  save(): void {
    this.$emit('save-route');
    this.close();
  }
}
