


























import { Vue, Component } from 'vue-property-decorator';
import { toastModule, toastModuleMapper } from '@/store/modules/toast';

@Component({
  computed: {
    ...toastModuleMapper.mapState(['isActive', 'color', 'message', 'timeout']),
  },
})
export default class Toast extends Vue {
  readonly toastModuleContext = toastModule.context(this.$store);

  isActive!: boolean;

  color!: string;

  message!: string;

  timeout!: number;

  get isToastActive(): boolean {
    return this.isActive;
  }

  set isToastActive(val: boolean) {
    this.close();
  }

  close(): void {
    this.toastModuleContext.actions.closeToast();
  }
}
