

















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class NavButton extends Vue {
  @Prop() icon!: string;
}
