






import { PropOptions } from 'vue';
import { findRealParent, propsBinder, LLayerGroup } from 'vue2-leaflet';
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  LatLngExpression,
  PolylineDecoratorOptions,
  polyline as LPolyline,
  polylineDecorator as LPolylineDecorator,
  DomEvent,
} from 'leaflet';

interface ExtendedVuePropOptions extends PropOptions {
  custom?: boolean;
}

interface ExtendedVueProps {
  [x: string]: ExtendedVuePropOptions;
}

interface DomListener {
  [eventName: string]: DomEvent.EventHandlerFn;
}

@Component
export default class Vue2LeafletPolylineDecorator extends Vue {
  // refer to https://github.com/jperelli/vue2-leaflet-polylinedecorator/blob/master/Vue2LeafletPolylinedecorator.vue
  @Prop({ type: Array, default: () => [] }) paths!: LatLngExpression[];

  @Prop({ type: Object, default: () => ({}) }) options!: PolylineDecoratorOptions | undefined;

  @Prop({ type: Boolean, default: true }) visible!: boolean;

  parentContainer!: LLayerGroup;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapObject!: any;

  ready = false;

  setVisible(newVal: boolean, oldVal: boolean): void {
    if (newVal === oldVal) return;
    if (newVal) {
      this.parentContainer.addLayer(this);
    } else {
      this.parentContainer.removeLayer(this);
    }
  }

  mounted(): void {
    const polyline = LPolyline(this.paths);
    this.mapObject = LPolylineDecorator(polyline, this.options);
    DomEvent.on(this.mapObject, this.$listeners as DomListener);
    (this.$options.props as ExtendedVueProps).visible.custom = true;
    propsBinder(this, this.mapObject, this.$options.props as ExtendedVueProps);
    this.ready = true;
    this.parentContainer = findRealParent(this.$parent);
    this.parentContainer.addLayer(this, !this.visible);
  }

  beforeDestroy(): void {
    this.parentContainer.removeLayer(this);
  }
}
