import { AxiosResponse, Canceler, CancelTokenSource } from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import SessionStorage from '@/services/session-storage';
import { LatLng } from '@/types';
import { RouteErrorResponse, RouteResponse } from '@/types/route';

export const AxiosUtils = {
  cancelRequest(cancelToken: Canceler | undefined, msg = ''): void {
    if (cancelToken !== undefined) {
      cancelToken(msg);
    }
  },
  ignoreFormerRequest(cancelToken: Canceler | undefined): void {
    this.cancelRequest(cancelToken, 'IGNORE');
  },
  ignoreRequestByTokenSource(cancelTokenSrc: CancelTokenSource): void {
    cancelTokenSrc.cancel('IGNORE');
  },
  formatQueryString(data: any): string {
    return `?${Object.keys(data)
      .map((k: string): string => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`)
      .join('&')}`;
  },
};

export const GeometryUtils = {
  coordsToLatLng: (coords: number[]): LatLng => {
    // return { lat, lng }
    return { lat: coords[1], lng: coords[0] };
  },
  coordsToLatLngTuple: (coords: number[]): [number, number] => {
    // return [lat, lng]
    return [coords[1], coords[0]];
  },
  wrapLatLng: (latlng: number[]): LatLng => {
    // return { lat, lng }
    return { lat: latlng[0], lng: latlng[1] };
  },
};

export const JWTUtils = {
  // Constants
  TOKEN_NAME: 'sgs.token',
  // Functions
  saveToken(token: string): void {
    SessionStorage.set(this.TOKEN_NAME, token);
  },
  getToken(): string | null {
    return SessionStorage.get(this.TOKEN_NAME);
  },
  deleteToken(): void {
    SessionStorage.clear(this.TOKEN_NAME);
  },
  isTokenExpired(token: string): boolean {
    try {
      const decoded = jwtDecode<JwtPayload>(token);
      const expTime = decoded.exp;
      const currentTime = Date.now() / 1000;
      return expTime ? currentTime >= expTime : true;
    } catch (error) {
      return true;
    }
  },
  isCurrentTokenValid(): boolean {
    const token = this.getToken();
    return token !== null && !this.isTokenExpired(token);
  },
  getAuthorizationHeader(): string {
    const token = this.getToken();
    return token ? `Bearer ${token}` : '';
  },
};

export const DateUtils = {
  getDateFromISOString: (value: string): string => {
    return value.substring(0, 10);
  },
  getDateTimeFromISOString: (value: string): string => {
    return `${value.substring(0, 10)} ${value.substring(11, 19)}`;
  },
};

export const TypeUtils = {
  isRouteError(response: RouteResponse | RouteErrorResponse): response is RouteErrorResponse {
    return 'pairID' in response && 'errorMessage' in response;
  },
};

export const FileUtils = {
  getDownloadedFile(
    response: AxiosResponse,
    typeName: string,
  ): { file: unknown; filename: string } {
    let filename = `UnknownFilename.${typeName}`;
    if (
      response.headers['content-disposition'] &&
      response.headers['content-disposition'].indexOf('attachment') !== -1
    ) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const found = filenameRegex.exec(response.headers['content-disposition']);
      if (found != null && found[1]) {
        filename = found[1].replace(/['"]/g, '');
      }
    }
    return {
      file: response.data,
      filename,
    };
  },
};

export const InputUtils = {
  isNumberOnly(evt: KeyboardEvent | ClipboardEvent): void {
    const regex = /^([0-9]*[.])?[0-9]+$/;
    if (evt.type === 'paste') {
      const clipboardData = (evt as ClipboardEvent)?.clipboardData;
      const text = clipboardData?.getData('text');
      if (!regex.test(text ?? '')) {
        evt.preventDefault();
      }
    }
    const charCode = (evt as KeyboardEvent).key;
    if (charCode === '.') {
      if ((evt?.target as HTMLInputElement)?.value.includes('.')) {
        evt.preventDefault();
      }
    } else if (charCode < '0' || charCode > '9') {
      evt.preventDefault();
    }
  },
};

export default {
  AxiosUtils,
  GeometryUtils,
  JWTUtils,
  DateUtils,
  FileUtils,
  InputUtils,
};
