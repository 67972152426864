







import { Vue, Component } from 'vue-property-decorator';
import AppHeader from '@/components/app/Header.vue';
import WebMap from '@/components/map/WebMap.vue';
import WebPanel from '@/components/panel/WebPanel.vue';
import Toast from '@/components/app/Toast.vue';

@Component({
  components: {
    AppHeader,
    WebMap,
    WebPanel,
    Toast,
  },
})
export default class Route extends Vue {}
