
































import { Component, Vue, Prop } from 'vue-property-decorator';
import { LControl } from 'vue2-leaflet';
import { TileLayerConfig, WMSLayer } from '@/types/map';

@Component({
  components: {
    LControl,
  },
})
export default class MapControls extends Vue {
  @Prop() wmsLayers!: WMSLayer[];

  @Prop() baseTileLayers!: TileLayerConfig[];
}
