import { Store } from 'vuex';
import { Getters, Mutations, Actions, Module, createMapper, Context } from 'vuex-smart-module';
import { FeatureCollection, LineString, Point } from 'geojson';
import Lodash from 'lodash';
import {
  RouteRestrictionTypes,
  RouteResponse,
  RouteSegment,
  SGSLocation,
  RouteGenerationRequest,
  RouteSearchRequest,
  RouteResult,
  RouteStatus,
  RouteErrorResponse,
  AvoidLocation,
} from '@/types/route';
import { mapModule } from '@/store/modules/map';
import { toastModule } from '@/store/modules/toast';
import ApiService from '@/services/api';
import { LatLong } from '@/types';
import { ConfigItem } from '@/types/app';
import { DateUtils, TypeUtils } from '@/utils';

class RouteState {
  isLoading = false;

  isSaving = false;

  isGeneratedRoute = false;

  isEnabledDefaultAvoidControls = false;

  currentIRNVersion = '';

  currentConfigs: ConfigItem[] = [];

  startLocation: SGSLocation | null = null;

  endLocation: SGSLocation | null = null;

  actualStartLocation: LatLong | null = null;

  actualEndLocation: LatLong | null = null;

  avoidLocations: AvoidLocation[] = [];

  includeLocations: LatLong[] = [];

  routeResponse: RouteResponse | null = null;

  routeErrorResponse: RouteErrorResponse | null = null;

  selectedSegmentIndex: number | null = null;
}

class RouteGetters extends Getters<RouteState> {
  get currentIRNVersion(): string {
    return DateUtils.getDateFromISOString(this.state.currentIRNVersion);
  }

  get routeResult(): RouteResult | null {
    const { routeResponse } = this.state;
    if (routeResponse) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { irnVersion, lastUpdateOn, confirmedOn, configUsed, ...result } = routeResponse;
      return result;
    }
    return null;
  }

  get routeStatus(): RouteStatus {
    const { routeResponse } = this.state;
    const status: RouteStatus = {
      irnVersion: routeResponse?.irnVersion,
      lastUpdateOn: routeResponse?.lastUpdateOn,
      confirmedOn: routeResponse?.confirmedOn,
      configUsed: routeResponse?.configUsed,
    };
    return status;
  }

  get isConfirmedRoute(): boolean {
    return !!this.getters.routeStatus.confirmedOn;
  }

  get routeSegments(): RouteSegment[] {
    const route = this.getters.routeResult?.route;
    return route ? route.segment : [];
  }

  get routeGeoJSON(): FeatureCollection<LineString> {
    const result: FeatureCollection<LineString> = { type: 'FeatureCollection', features: [] };
    const route = this.getters.routeResult?.route;
    if (route) {
      result.features = route.segment.map((s, idx) => ({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: s.shape.coordinates.map((o) => o.slice().reverse()),
        },
        properties: {
          location: s.name,
          segmentIndex: idx,
          isProhibited: (s?.np || 0) > 0,
        },
      }));
    }
    return result;
  }

  get routeRestrictionGeoJSON(): FeatureCollection<Point> {
    const result: FeatureCollection<Point> = { type: 'FeatureCollection', features: [] };
    const { routeSegments } = this.getters;
    if (routeSegments) {
      result.features = routeSegments.flatMap((seg) =>
        RouteRestrictionTypes.flatMap((type) =>
          (seg[type] ?? []).map((info) => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: info.shape.slice().reverse(),
            },
            properties: {
              type,
              desc: info.desc,
            },
          })),
        ),
      );
    }
    return result;
  }
}

class RouteMutations extends Mutations<RouteState> {
  updateLoadingState(payload: boolean) {
    this.state.isLoading = payload;
  }

  updateSavingState(payload: boolean) {
    this.state.isSaving = payload;
  }

  updateGeneratedRouteState(payload: boolean) {
    this.state.isGeneratedRoute = payload;
  }

  updateCurrentIRNVersion(payload: string) {
    this.state.currentIRNVersion = payload;
  }

  updateCurrentConfigs(payload: ConfigItem[]) {
    this.state.currentConfigs = payload;
  }

  updateStartLocation(payload: SGSLocation | null) {
    this.state.startLocation = payload;
  }

  updateEndLocation(payload: SGSLocation | null) {
    this.state.endLocation = payload;
  }

  updateActualStartLocation(payload: LatLong | null) {
    this.state.actualStartLocation = payload;
  }

  updateActualEndLocation(payload: LatLong | null) {
    this.state.actualEndLocation = payload;
  }

  updateRouteResponse(payload: RouteResponse | null) {
    this.state.routeResponse = payload;
  }

  updateSelectedSegmentIndex(payload: number | null) {
    this.state.selectedSegmentIndex = payload;
  }

  addAvoidLocation(payload: AvoidLocation) {
    const list = Lodash.uniqWith([...this.state.avoidLocations, payload], Lodash.isEqual);
    this.state.avoidLocations = list;
  }

  removeAvoidLocation(payload: AvoidLocation) {
    const list = this.state.avoidLocations.filter((a) => !Lodash.isEqual(a, payload));
    this.state.avoidLocations = list;
  }

  updateAvoidLocation(payload: { index: number; location: AvoidLocation }) {
    this.state.avoidLocations[payload.index] = payload.location;
  }

  updateAvoidLocations(payload: AvoidLocation[]) {
    this.state.avoidLocations = payload;
  }

  addIncludeLocation(payload: LatLong) {
    const list = Lodash.uniqWith([...this.state.includeLocations, payload], Lodash.isEqual);
    this.state.includeLocations = list;
  }

  removeIncludeLocation(payload: LatLong) {
    const list = this.state.includeLocations.filter((a) => !Lodash.isEqual(a, payload));
    this.state.includeLocations = list;
  }

  updateIncludeLocation(payload: { index: number; location: LatLong }) {
    this.state.includeLocations[payload.index] = payload.location;
  }

  updateIncludeLocations(payload: LatLong[]) {
    this.state.includeLocations = payload;
  }

  updateSegmentRemark(payload: { index: number; remark: string }) {
    const { routeResponse } = this.state;
    if (routeResponse) {
      const remark = payload.remark !== '' ? payload.remark : undefined;
      routeResponse.route.segment[payload.index].remark = remark;
    }
  }

  updateRouteErrorResponse(payload: RouteErrorResponse | null) {
    this.state.routeErrorResponse = payload;
  }

  updateDefaultAvoidControlsDisplay(bool: boolean) {
    this.state.isEnabledDefaultAvoidControls = bool;
  }
}

class RouteActions extends Actions<RouteState, RouteGetters, RouteMutations, RouteActions> {
  mapModuleContext!: Context<typeof mapModule>;

  toastModuleContext!: Context<typeof toastModule>;

  $init(store: Store<unknown>): void {
    this.mapModuleContext = mapModule.context(store);
    this.toastModuleContext = toastModule.context(store);
  }

  clearLocation() {
    this.mutations.updateStartLocation(null);
    this.mutations.updateEndLocation(null);
    this.actions.clearRoute();
  }

  clearRoute() {
    this.mutations.updateRouteErrorResponse(null);
    this.mutations.updateRouteResponse(null);
    this.mutations.updateActualStartLocation(null);
    this.mutations.updateActualEndLocation(null);
    this.mutations.updateAvoidLocations([]);
    this.mutations.updateIncludeLocations([]);
    this.mutations.updateSelectedSegmentIndex(null);
    this.mutations.updateGeneratedRouteState(false);
    this.mutations.updateCurrentIRNVersion('');
    this.mutations.updateCurrentConfigs([]);
  }

  updateRoute(response: RouteResponse) {
    const actualStart = response.payload.start;
    const actualEnd = response.payload.end;
    const avoids: AvoidLocation[] =
      response.payload.avoid?.map((a) => ({
        lat: a.lat,
        long: a.lon,
        isDefault: a.default === 1,
      })) || [];
    const includes = response.payload.include?.map((i) => ({ lat: i.lat, long: i.lon })) || [];
    this.mutations.updateRouteResponse(response);
    this.mutations.updateActualStartLocation({ lat: actualStart.lat, long: actualStart.lon });
    this.mutations.updateActualEndLocation({ lat: actualEnd.lat, long: actualEnd.lon });
    this.mutations.updateAvoidLocations(avoids);
    this.mutations.updateIncludeLocations(includes);
  }

  updateErrorRoute(response: RouteErrorResponse) {
    this.mutations.updateRouteErrorResponse(response);
    // Assign actual location such that user can drag the location marker to re-generate route
    const { startLocation, endLocation } = this.state;
    if (startLocation && endLocation) {
      this.mutations.updateActualStartLocation({
        lat: startLocation.outGeom.y,
        long: startLocation.outGeom.x,
      });
      this.mutations.updateActualEndLocation({
        lat: endLocation.inGeom.y,
        long: endLocation.inGeom.x,
      });
    }
  }

  async getRoute() {
    const { startLocation, endLocation } = this.state;
    if (startLocation && endLocation) {
      try {
        // Update loading state
        this.mapModuleContext.mutations.updateLoadingState(true);
        this.mutations.updateLoadingState(true);
        // Clear existing route
        this.actions.clearRoute();
        // Get response
        const payload: RouteSearchRequest = { oid: startLocation.id, did: endLocation.id };
        const response = await ApiService.getRoute(payload);
        // Get current IRN Version & configs
        const currentIRN = await ApiService.getIRNVersion();
        const currentConfigs = await ApiService.getConfigs();
        // Update store
        if (TypeUtils.isRouteError(response)) {
          this.actions.updateErrorRoute(response);
        } else {
          this.actions.updateRoute(response);
        }
        this.mutations.updateCurrentIRNVersion(currentIRN);
        this.mutations.updateCurrentConfigs(currentConfigs);
      } catch (error) {
        if (typeof error === 'string') {
          this.toastModuleContext.actions.openErrorToast({ message: error });
        }
        this.actions.clearRoute();
      } finally {
        this.mapModuleContext.mutations.updateLoadingState(false);
        this.mutations.updateLoadingState(false);
      }
    }
  }

  async generateRoute() {
    const { routeResult, routeSegments } = this.getters;
    const { routeResponse, routeErrorResponse } = this.state;
    const { startLocation, endLocation } = this.state;
    const { actualStartLocation, actualEndLocation } = this.state;
    const { avoidLocations, includeLocations } = this.state;
    if (startLocation && endLocation) {
      try {
        // Update loading state
        this.mapModuleContext.mutations.updateLoadingState(true);
        this.mutations.updateLoadingState(true);
        // Format request payload
        const pairID = routeResult?.pairID || routeErrorResponse?.pairID;
        const requestPayload: RouteGenerationRequest = { pairID: pairID! };
        if (avoidLocations.length > 0) {
          requestPayload.avoid = Lodash.uniqWith(
            Lodash.orderBy(avoidLocations, 'isDefault', 'desc'),
            (a, b) => `${a.lat}` === `${b.lat}` && `${a.long}` === `${b.long}`,
          ).filter((v) => v.isDefault === false);
        }
        if (includeLocations.length > 0) requestPayload.include = includeLocations;
        if (actualStartLocation) requestPayload.start = actualStartLocation;
        if (actualEndLocation) requestPayload.end = actualEndLocation;
        // Get response
        const response = await ApiService.generateRoute(requestPayload);
        if (!TypeUtils.isRouteError(response)) {
          // Backup remarks
          const remarks = routeSegments.map((s) => s.remark);
          // Clear existing route
          this.actions.clearRoute();
          // Get current IRN Version & configs
          const currentIRN = await ApiService.getIRNVersion();
          const currentConfigs = await ApiService.getConfigs();
          // Assign remarks to response
          response.route.segment.forEach((s, idx) => {
            s.remark = remarks[idx];
          });
          // Update store
          this.actions.updateRoute(response);
          this.mutations.updateGeneratedRouteState(true);
          this.mutations.updateCurrentIRNVersion(currentIRN);
          this.mutations.updateCurrentConfigs(currentConfigs);
        } else {
          // Handle route error
          this.toastModuleContext.actions.openErrorToast({ message: response.errorMessage });
          if (routeResponse) this.actions.updateRoute(routeResponse);
          if (routeErrorResponse) this.actions.updateErrorRoute(routeErrorResponse);
        }
      } catch (error) {
        if (typeof error === 'string') {
          this.toastModuleContext.actions.openErrorToast({ message: error });
        }
        this.actions.clearRoute();
      } finally {
        this.mapModuleContext.mutations.updateLoadingState(false);
        this.mutations.updateLoadingState(false);
      }
    }
  }
}

export const routeModule = new Module({
  state: RouteState,
  getters: RouteGetters,
  mutations: RouteMutations,
  actions: RouteActions,
});

export const routeModuleMapper = createMapper(routeModule);
