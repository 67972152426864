


















































































import Lodash from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  DragEndEvent,
  LeafletMouseEvent,
  Map as LeafletMap,
  PopupOptions,
  circleMarker,
  CircleMarker,
} from 'leaflet';
import { LControl, LPopup, LMarker, LIcon, LFeatureGroup } from 'vue2-leaflet';
import { routeModule } from '@/store/modules/route';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { ProjectLogoMap, ProjectOptions } from '@/configs/project.config';
import ApiService from '@/services/api';
import { LatLng, LatLong } from '@/types';
import { MapPaneConfig } from '@/types/map';
import { WebMapPaneConfig } from '@/configs/map.config';
import ActionButton from '@/components/map/popups/ActionButton.vue';
import { mapModule } from '@/store/modules/map';
import { toastModule } from '@/store/modules/toast';
import { LocationDto } from '@/types/api';

@Component({
  components: {
    LControl,
    LPopup,
    LMarker,
    LIcon,
    LFeatureGroup,
    ActionButton,
  },
})
export default class DefaultAvoidLocationControls extends Vue {
  $refs!: {
    avoidMarkerGroupRef: LFeatureGroup;
  };

  @Prop() map!: LeafletMap;

  readonly routeModuleContext = routeModule.context(this.$store);

  readonly mapModuleContext = mapModule.context(this.$store);

  readonly toastModuleContext = toastModule.context(this.$store);

  controlTooltip = '變更預設避開路段'; // 規劃避開路段

  get isEnabled(): boolean {
    return this.routeModuleContext.state.isEnabledDefaultAvoidControls;
  }

  set isEnabled(val: boolean) {
    this.routeModuleContext.commit('updateDefaultAvoidControlsDisplay', val);
  }

  get isAnyRouteResponse(): boolean {
    const { routeResponse, routeErrorResponse } = this.routeModuleContext.state;
    return routeResponse != null || routeErrorResponse != null;
  }

  isSavingLocation = false;

  projectList = ProjectOptions;

  projectLogoMap = ProjectLogoMap;

  selectedProject: { text: string; value: string } | null = null;

  defaultAvoidLocationList: LatLong[] = [];

  locationList: LocationDto[] = [];

  mapPaneConfig: MapPaneConfig = WebMapPaneConfig;

  avoidLocationIconAnchor: number[] = [8, 12];

  cursorMarker: CircleMarker | null = null;

  cursorMarkerLatLng: LatLng | null = null;

  popupOptions: PopupOptions = {
    closeButton: false,
    maxWidth: 300,
    minWidth: 220,
    className: 'map-route-location-popup',
    pane: WebMapPaneConfig.MapPopup.pane,
  };

  setCursorLatLng(evt: LeafletMouseEvent): void {
    if (this.cursorMarker == null) {
      this.setReminderCursor(evt.latlng);
    } else {
      this.cursorMarker.setLatLng(evt.latlng);
    }
  }

  setReminderCursor(latlng: LatLng): void {
    this.cursorMarker = circleMarker(latlng, {
      radius: 7,
      weight: 2,
      stroke: true,
      className: 'map-crosshair-cursor',
    }).addTo(this.map);
    this.cursorMarker.bindTooltip('', {
      permanent: true,
      direction: 'bottom',
      offset: [0, 6],
    });
    this.setReminderCursorTooltipContent();
  }

  setReminderCursorTooltipContent(): void {
    if (this.cursorMarker != null) {
      this.cursorMarker.setTooltipContent(
        this.selectedProject != null ? '按右鍵新增避開路段' : '請選擇項目',
      );
    }
  }

  start(): void {
    if (this.isSavingLocation) {
      return;
    }
    if (!this.isEnabled === false) {
      this.cancel();
    } else {
      this.isEnabled = true;
    }
    this.routeModuleContext.actions.clearLocation();
  }

  async save(): Promise<void> {
    if (!this.selectedProject) {
      return;
    }
    try {
      this.isSavingLocation = true;
      this.mapModuleContext.commit('updateLoadingState', true);
      await ApiService.saveDefaultAvoidLocation({
        project: this.selectedProject.value,
        avoid: this.defaultAvoidLocationList,
      });
      this.toastModuleContext.actions.openToast({
        color: 'success',
        message: '成功儲存',
      });
    } catch (e) {
      this.toastModuleContext.actions.openErrorToast({ message: '儲存時發生錯誤' });
    } finally {
      this.isSavingLocation = false;
      this.mapModuleContext.commit('updateLoadingState', false);
    }
  }

  cancel(): void {
    this.isEnabled = false;
    this.selectedProject = null;
    this.defaultAvoidLocationList = [];
    this.locationList = [];
  }

  onContextMenu(evt: LeafletMouseEvent): void {
    if (this.selectedProject != null) {
      this.defaultAvoidLocationList = Lodash.uniqWith(
        [
          ...this.defaultAvoidLocationList,
          {
            lat: evt.latlng.lat,
            long: evt.latlng.lng,
          },
        ],
        Lodash.isEqual,
      );
    }
  }

  async onMarkerDragEnd(evt: DragEndEvent, index: number): Promise<void> {
    if (this.selectedProject != null) {
      const latLng = evt.target.getLatLng();
      this.defaultAvoidLocationList[index] = {
        lat: latLng.lat,
        long: latLng.lng,
      };
    }
  }

  removeLocation(location: LatLong): void {
    if (this.selectedProject != null) {
      this.defaultAvoidLocationList = this.defaultAvoidLocationList.filter(
        (a) => !Lodash.isEqual(a, location),
      );
      this.map.closePopup();
    }
  }

  @Watch('isEnabled')
  onChangeIsEnabled(): void {
    if (this.isEnabled) {
      this.map.on('contextmenu', this.onContextMenu);
      this.map.on('mousemove', this.setCursorLatLng);
      this.mapModuleContext.commit('enableAllWMSTileLayerVisibleTemporarily');
    } else {
      this.map.off('contextmenu', this.onContextMenu);
      this.map.off('mousemove', this.setCursorLatLng);
      this.cursorMarker?.remove();
      this.cursorMarker = null;
      this.mapModuleContext.commit('restoreWMSTileLayerVisible');
    }
  }

  @Watch('isAnyRouteResponse')
  onChangeIsAnyRouteResponse(): void {
    if (this.isEnabled && this.isAnyRouteResponse) {
      this.isEnabled = false;
    }
  }

  @Watch('selectedProject')
  async onChangeSelectedProject(): Promise<void> {
    if (this.selectedProject != null) {
      try {
        const avoidLocationdto = await ApiService.getDefaultAvoidLocation(
          this.selectedProject.value,
        );
        this.locationList = await ApiService.getLocationList(this.selectedProject.value);
        this.defaultAvoidLocationList = avoidLocationdto?.avoid ?? [];
        await this.$nextTick();
        const bounds = this.$refs.avoidMarkerGroupRef.mapObject.getBounds();
        if (bounds.isValid()) {
          this.map.flyToBounds(bounds, { animate: false, padding: [100, 100] });
        }
      } catch (error) {
        this.locationList = [];
        this.defaultAvoidLocationList = [];
        if (typeof error === 'string') {
          this.toastModuleContext.actions.openErrorToast({ message: error });
        }
      }
    }
    this.setReminderCursorTooltipContent();
  }

  beforeDestroy(): void {
    if (this.isEnabled) {
      this.isEnabled = false;
      ApiService.cancel.GET_DEFAULT_AVOID_LOCATION();
      ApiService.cancel.SAVE_DEFAULT_AVOID_LOCATION();
      this.mapModuleContext.commit('restoreWMSTileLayerVisible');
    }
  }
}
