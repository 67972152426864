import FileSaver from 'file-saver';
import axios, { AxiosError, AxiosInstance, Canceler } from 'axios';
import {
  LocationSearchRequest,
  LocationSearchResponse,
  RouteGenerationRequest,
  RouteSearchRequest,
  RouteResponse,
  RouteSaveRequest,
  SGSLocation,
  RouteListResponse,
  DestinationSuggestionRequest,
  OriginSuggestionRequest,
  RouteErrorResponse,
  RouteAreaSearchRequest,
} from '@/types/route';
import { AxiosUtils, FileUtils, JWTUtils } from '@/utils';
import {
  ConfigItem,
  ConfigRequest,
  ConfigResponse,
  LoginPayload,
  LoginResponse,
} from '@/types/app';
import { DefaultAvoidLocationDto, LocationDto } from '@/types/api';

enum CancelableTask {
  RS = 'ROUTE_SEARCH',
  RG = 'ROUTE_GENERATION',
  LS = 'LOCATION_SEARCH',
  GC = 'GET_CONFIGS',
  GD = 'GET_DESTINATIONS',
  GO = 'GET_ORIGINS',
  GL = 'GET_LOCATION',
  GDAL = 'GET_DEFAULT_AVOID_LOCATION',
  SDAL = 'SAVE_DEFAULT_AVOID_LOCATION',
}

type Cancelers = {
  [x in CancelableTask]: Canceler | undefined;
};

const RequestCancelers: Cancelers = {} as Cancelers;

const LocationSearchCancelers: { [x: string]: Canceler | undefined } = {};

const requestHeaders: { [x: string]: string } = {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
};

const ApiServiceInstance: AxiosInstance = axios.create({
  baseURL: '/api',
  headers: requestHeaders,
});

ApiServiceInstance.interceptors.request.use(
  (config) => {
    config.headers = { ...config.headers, Authorization: JWTUtils.getAuthorizationHeader() };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

ApiServiceInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    // Request is canceled
    if (axios.isCancel(error) && error.message === 'IGNORE') {
      return new Promise(() => null); // break the promise chain direcly;
    }
    // JWT token is invalid, redirect to login page
    if (error.response?.status === 401) {
      JWTUtils.deleteToken();
      window.location.href = '/login';
      return new Promise(() => null); // break the promise chain direcly;
    }
    // Return error
    return Promise.reject(error);
  },
);

const defaultErrorMessage = '發生未知的錯誤';

function getErrorMessage(error: unknown) {
  if (axios.isAxiosError(error)) {
    return error.response?.data?.Message || defaultErrorMessage;
  }
  return defaultErrorMessage;
}

function isRouteErrorResponse(error: AxiosError) {
  const res = error.response?.data;
  return res.pairID && res.errorMessage;
}

const ApiService = {
  /**
   * @description Cancel Former Request
   */
  cancel: {
    [CancelableTask.RS](): void {
      AxiosUtils.ignoreFormerRequest(RequestCancelers.ROUTE_SEARCH);
    },
    [CancelableTask.RG](): void {
      AxiosUtils.ignoreFormerRequest(RequestCancelers.ROUTE_GENERATION);
    },
    [CancelableTask.LS](tokenID: string): void {
      AxiosUtils.ignoreFormerRequest(LocationSearchCancelers[tokenID]);
    },
    [CancelableTask.GC](): void {
      AxiosUtils.ignoreFormerRequest(RequestCancelers.GET_CONFIGS);
    },
    [CancelableTask.GD](): void {
      AxiosUtils.ignoreFormerRequest(RequestCancelers.GET_DESTINATIONS);
    },
    [CancelableTask.GO](): void {
      AxiosUtils.ignoreFormerRequest(RequestCancelers.GET_ORIGINS);
    },
    [CancelableTask.GL](): void {
      AxiosUtils.ignoreFormerRequest(RequestCancelers.GET_LOCATION);
    },
    [CancelableTask.GDAL](): void {
      AxiosUtils.ignoreFormerRequest(RequestCancelers.GET_DEFAULT_AVOID_LOCATION);
    },
    [CancelableTask.SDAL](): void {
      AxiosUtils.ignoreFormerRequest(RequestCancelers.SAVE_DEFAULT_AVOID_LOCATION);
    },
  },
  async login(payload: LoginPayload): Promise<LoginResponse> {
    try {
      const res = await ApiServiceInstance.post<LoginResponse>('/login', payload);
      return res.data;
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
  async getIRNVersion(): Promise<string> {
    try {
      const res = await ApiServiceInstance.get<string>('/version/irn');
      return res.data;
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
  async getSGSLocationSyncTime(): Promise<string> {
    try {
      const res = await ApiServiceInstance.get<string>('/version/sgsloc');
      return res.data;
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
  async getStationUploadTime(): Promise<string> {
    try {
      const res = await ApiServiceInstance.get<string>('/version/ftp/station');
      return res.data;
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
  async getJMPSegmentUploadTime(): Promise<string> {
    try {
      const res = await ApiServiceInstance.get<string>('/version/ftp/jmpsegment');
      return res.data;
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
  async getRoadHazardUploadTime(): Promise<string> {
    try {
      const res = await ApiServiceInstance.get<string>('/version/risk');
      return res.data;
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
  async getConfigs(): Promise<ConfigResponse> {
    try {
      const res = await ApiServiceInstance.get<ConfigResponse>('/config', {
        cancelToken: new axios.CancelToken((c) => {
          RequestCancelers.GET_CONFIGS = c;
        }),
      });
      return res.data;
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
  async updateConfig(queryParams: ConfigRequest, payload: ConfigItem): Promise<void> {
    try {
      const queryString = AxiosUtils.formatQueryString(queryParams);
      await ApiServiceInstance.put(`/config${queryString}`, payload);
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
  async searchLocation(
    queryParams: LocationSearchRequest,
    tokenID: string,
  ): Promise<LocationSearchResponse> {
    try {
      this.cancel[CancelableTask.LS](tokenID);
      const queryString = AxiosUtils.formatQueryString(queryParams);
      const res = await ApiServiceInstance.get<LocationSearchResponse>(`/search${queryString}`, {
        cancelToken: new axios.CancelToken((c) => {
          LocationSearchCancelers[tokenID] = c;
        }),
      });
      return res.data;
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
  async getRoute(queryParams: RouteSearchRequest): Promise<RouteResponse | RouteErrorResponse> {
    try {
      this.cancel[CancelableTask.RS]();
      const queryString = AxiosUtils.formatQueryString(queryParams);
      const res = await ApiServiceInstance.get<RouteResponse>(`/getRoute${queryString}`, {
        cancelToken: new axios.CancelToken((c) => {
          RequestCancelers.ROUTE_SEARCH = c;
        }),
      });
      return res.data;
    } catch (error) {
      if (axios.isAxiosError(error) && isRouteErrorResponse(error)) {
        return error.response?.data as RouteErrorResponse;
      }
      throw getErrorMessage(error);
    }
  },
  async generateRoute(
    payload: RouteGenerationRequest,
  ): Promise<RouteResponse | RouteErrorResponse> {
    try {
      this.cancel[CancelableTask.RG]();
      const res = await ApiServiceInstance.post<RouteResponse>(`/generateRoute`, payload, {
        cancelToken: new axios.CancelToken((c) => {
          RequestCancelers.ROUTE_GENERATION = c;
        }),
      });
      return res.data;
    } catch (error) {
      if (axios.isAxiosError(error) && isRouteErrorResponse(error)) {
        return error.response?.data as RouteErrorResponse;
      }
      throw getErrorMessage(error);
    }
  },
  async saveRoute(payload: RouteSaveRequest): Promise<void> {
    try {
      await ApiServiceInstance.post('/saveRoute', payload);
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
  async deleteRoute(pairID: number): Promise<void> {
    try {
      await ApiServiceInstance.delete(`/deleteRoute/${pairID}`);
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
  async getDestinations(queryParams: DestinationSuggestionRequest): Promise<SGSLocation[]> {
    try {
      this.cancel[CancelableTask.GD]();
      const queryString = AxiosUtils.formatQueryString(queryParams);
      const url = `/location/destination${queryString}`;
      const res = await ApiServiceInstance.get<SGSLocation[]>(url, {
        cancelToken: new axios.CancelToken((c) => {
          RequestCancelers.GET_DESTINATIONS = c;
        }),
      });
      return res.data;
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
  async getOrigins(queryParams: OriginSuggestionRequest): Promise<SGSLocation[]> {
    try {
      this.cancel[CancelableTask.GO]();
      const queryString = AxiosUtils.formatQueryString(queryParams);
      const url = `/location/origin${queryString}`;
      const res = await ApiServiceInstance.get<SGSLocation[]>(url, {
        cancelToken: new axios.CancelToken((c) => {
          RequestCancelers.GET_ORIGINS = c;
        }),
      });
      return res.data;
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
  async getRouteList(): Promise<RouteListResponse> {
    try {
      const res = await ApiServiceInstance.get<RouteListResponse>('/getRouteList');
      return res.data;
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
  async exportRouteList(): Promise<void> {
    try {
      const res = await ApiServiceInstance.get('/exportRouteList', {
        responseType: 'arraybuffer',
      });
      const fileInfo = FileUtils.getDownloadedFile(res, 'xlsx');
      return FileSaver.saveAs(
        new Blob([fileInfo.file as Blob], { type: 'octet/stream' }),
        fileInfo.filename,
      );
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
  async getRouteListByArea(payload: RouteAreaSearchRequest): Promise<RouteListResponse> {
    try {
      const url = '/getRouteList/searchArea';
      const res = await ApiServiceInstance.post<RouteListResponse>(url, payload);
      return res.data;
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
  async getLocationList(project: string): Promise<LocationDto[]> {
    try {
      const url = `/location/project/${project}`;
      const res = await ApiServiceInstance.get<LocationDto[]>(url, {
        cancelToken: new axios.CancelToken((c) => {
          RequestCancelers.GET_LOCATION = c;
        }),
      });
      return res.data ?? [];
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
  async getDefaultAvoidLocation(project: string): Promise<DefaultAvoidLocationDto | null> {
    try {
      const queryString = AxiosUtils.formatQueryString({
        project,
      });
      const url = `/avoidLocation${queryString}`;
      const res = await ApiServiceInstance.get<DefaultAvoidLocationDto>(url, {
        cancelToken: new axios.CancelToken((c) => {
          RequestCancelers.GET_DEFAULT_AVOID_LOCATION = c;
        }),
      });
      return res.data || null;
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
  async saveDefaultAvoidLocation(payload: DefaultAvoidLocationDto): Promise<boolean> {
    try {
      const url = `/avoidLocation`;
      await ApiServiceInstance.post(url, payload, {
        cancelToken: new axios.CancelToken((c) => {
          RequestCancelers.SAVE_DEFAULT_AVOID_LOCATION = c;
        }),
      });
      return true;
    } catch (error) {
      throw getErrorMessage(error);
    }
  },
};

export default ApiService;
