
































import { Vue, Component, Prop } from 'vue-property-decorator';
import Lodash from 'lodash';
import { ConfigItem } from '@/types/app';

type ConfigDifferences = {
  key: string;
  currentValue: string | number;
  usedValue: string | number;
}[];

@Component
export default class ConfigDifferenceDialog extends Vue {
  @Prop({ type: Boolean, default: false }) value!: boolean;

  @Prop({ type: Array, default: () => [] }) currentConfig!: ConfigItem[];

  @Prop({ type: Array, default: () => [] }) configUsed!: ConfigItem[];

  get difference(): ConfigDifferences {
    const result = [] as ConfigDifferences;

    const currentKeys = this.currentConfig.map((o) => o.Key);
    const usedKeys = this.configUsed.map((o) => o.Key);
    const keys = Lodash.union(currentKeys, usedKeys);

    keys.forEach((key) => {
      const currentValue = this.currentConfig.find((o) => o.Key === key)?.Value ?? '';
      const usedValue = this.configUsed.find((o) => o.Key === key)?.Value ?? '';
      if (currentValue !== usedValue) {
        result.push({ key, currentValue, usedValue });
      }
    });

    return result;
  }

  close(): void {
    this.$emit('input', false);
  }
}
