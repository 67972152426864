














import { Component, Prop, Vue } from 'vue-property-decorator';
import { CSSProperties } from '@/types';

@Component
export default class PopupTemplate extends Vue {
  @Prop({ type: Boolean, default: true }) enableHeaderStyle!: boolean;

  @Prop({ type: String, default: null }) headerColor!: string | null;

  get headerStyle(): CSSProperties {
    return {
      background: this.headerColor || '#26a69a',
    };
  }
}
