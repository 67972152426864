
































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { routeModule } from '@/store/modules/route';
import { toastModule } from '@/store/modules/toast';
import ApiService from '@/services/api';
import { ConfigItem, ConfigResponse } from '@/types/app';
import { InputUtils } from '@/utils';

@Component
export default class ConfigDialog extends Vue {
  @Prop({ type: Boolean, default: false }) value!: boolean;

  $refs!: {
    formRef: HTMLFormElement;
  };

  readonly routeModuleContext = routeModule.context(this.$store);

  readonly toastModuleContext = toastModule.context(this.$store);

  isShowConfigDialog!: boolean;

  isLoading = false;

  isSaving = false;

  isError = false;

  hiddenConfigs = ['Route:Vehicle:Type'];

  configs = [] as ConfigResponse;

  updatedConfigs = [] as ConfigItem[];

  restrictNumOnly = InputUtils.isNumberOnly;

  updateConfig(key: string, newVal: string): void {
    const idx = this.updatedConfigs.findIndex((c) => c.Key === key);
    if (idx > -1) {
      this.updatedConfigs[idx].Value = newVal;
    } else {
      const item: ConfigItem = { Key: key, Value: newVal };
      this.updatedConfigs.push(item);
    }
  }

  reset(): void {
    ApiService.cancel.GET_CONFIGS();
    this.configs = [];
    this.updatedConfigs = [];
    this.isLoading = false;
    this.isSaving = false;
    this.isError = false;
  }

  close(): void {
    this.reset();
    this.$emit('input', false);
  }

  async save(): Promise<void> {
    if (!this.$refs.formRef.validate()) {
      return;
    }
    try {
      this.isSaving = true;
      // Update database
      await Promise.all(
        this.updatedConfigs.map(async (c) => {
          await ApiService.updateConfig({ key: c.Key }, c);
        }),
      );
      // Updated successfully
      this.toastModuleContext.actions.openToast({
        color: 'success',
        message: '成功儲存',
      });
      // Update store
      const currentConfigs = await ApiService.getConfigs();
      this.routeModuleContext.mutations.updateCurrentConfigs(currentConfigs);
      // Close dialog
      this.close();
    } catch (error) {
      const message = typeof error === 'string' ? error : '發生未知的錯誤';
      this.toastModuleContext.actions.openErrorToast({ message });
    } finally {
      this.isSaving = false;
    }
  }

  @Watch('value')
  async onDialogStateChange(isOpen: boolean): Promise<void> {
    if (isOpen) {
      try {
        this.isLoading = true;
        this.configs = await ApiService.getConfigs();
      } catch (error) {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    }
  }
}
