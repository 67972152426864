import Vue from 'vue';
import Vuex from 'vuex';
import { createStore, Module } from 'vuex-smart-module';
import { routeModule } from '@/store/modules/route';
import { mapModule } from '@/store/modules/map';
import { toastModule } from '@/store/modules/toast';
import { routeListModule } from './modules/route-list';

Vue.use(Vuex);

const rootModule = new Module({
  modules: {
    routeModule,
    routeListModule,
    mapModule,
    toastModule,
  },
});

const store = createStore(rootModule, {
  strict: process.env.NODE_ENV !== 'production',
});

export default store;
