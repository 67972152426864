import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Main from '@/views/Main.vue';
import Route from '@/views/Route.vue';
import RouteList from '@/views/RouteList.vue';
import { JWTUtils } from '@/utils';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Main,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'route-list',
        component: RouteList,
      },
      {
        path: 'route',
        name: 'route',
        component: Route,
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    beforeEnter: (to, from, next) => {
      JWTUtils.isCurrentTokenValid() ? next('/') : next();
    },
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !JWTUtils.isCurrentTokenValid()) {
    const query = to.fullPath === '/' ? '' : `?redirect=${to.fullPath}`;
    window.location.href = `/login${query}`;
    return () => null;
  }
  return next();
});

export default router;
