import { render, staticRenderFns } from "./SearchBar.vue?vue&type=template&id=ebbc798a&scoped=true&"
import script from "./SearchBar.vue?vue&type=script&lang=ts&"
export * from "./SearchBar.vue?vue&type=script&lang=ts&"
import style0 from "./SearchBar.vue?vue&type=style&index=0&id=ebbc798a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebbc798a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VChip,VChipGroup,VIcon})
