import { TileLayerOptions } from 'leaflet';
import { MapConfig, MapColorConfig, TileLayerConfig, MapPaneConfig, WMSLayer } from '@/types/map';

export const LayerServiceURL = '/api/layer/map';

export const WebMapConfig: MapConfig = {
  defaultZoom: 11,
  defaultCenter: { lat: 22.34848639255935, lng: 114.12117004394533 },
  maxZoom: 22,
  minZoom: 10,
  maxBoundsViscosity: 1,
  maxBounds: [
    { lat: 21.407037105115787, lng: 112.32147216796876 }, // southWest
    { lat: 23.375024146236424, lng: 115.94696044921876 }, // northEast
  ],
  attributionText:
    '<u target="_blank" class="copyrightDiv">&copy; 地圖版權屬香港特區政府</u><div style="width:28px;height:28px;display:inline-flex;background:url(https://api.hkmapservice.gov.hk/mapapi/landsdlogo.jpg);background-size:28px;"></div>',
};

export const WebMapPaneConfig: MapPaneConfig = {
  MapPopup: {
    pane: 'map-popup',
    style: {
      zIndex: '801',
    },
  },
  LocationMarker: {
    pane: 'location-markers',
    style: {
      zIndex: '604',
    },
  },
  OriginalLocationMarker: {
    pane: 'original-location-markers',
    style: {
      zIndex: '603',
    },
  },
  SegmentTailMarker: {
    pane: 'segment-tail-markers',
    style: {
      zIndex: '602',
    },
  },
  TrafficSignMarker: {
    pane: 'traffic-sign-markers',
    style: {
      zIndex: '601',
    },
  },
  LabelLayer: {
    pane: 'label-layer',
    style: {
      zIndex: '501',
    },
  },
  RouteArrow: {
    pane: 'route-arrow',
    style: {
      zIndex: '402',
    },
  },
  RoutePath: {
    pane: 'route-paths',
    style: {
      zIndex: '401',
    },
  },
  WMSLayer: {
    pane: 'wms-layer',
    style: {
      zIndex: '202',
    },
  },
  BaseLayer: {
    pane: 'base-layer',
    style: {
      zIndex: '201',
    },
  },
};

const BaseTileLayerOptions: TileLayerOptions = {
  maxZoom: WebMapConfig.maxZoom,
  maxNativeZoom: 20,
};

export const LabelTileLayer: TileLayerConfig = {
  name: 'MapLabel',
  pane: WebMapPaneConfig.LabelLayer.pane,
  visible: true,
  url: 'https://mapapi.geodata.gov.hk/gs/api/v1.0.0/xyz/label/hk/tc/WGS84/{z}/{x}/{y}.png',
  options: BaseTileLayerOptions,
};

export const BaseTileLayers: TileLayerConfig[] = [
  {
    name: 'TopographicMap',
    pane: WebMapPaneConfig.BaseLayer.pane,
    layerType: 'base',
    visible: true,
    url: 'https://mapapi.geodata.gov.hk/gs/api/v1.0.0/xyz/basemap/wgs84/{z}/{x}/{y}.png',
    options: BaseTileLayerOptions,
    toggleButton: {
      image: require('@/assets/img/btn_topographic_map.png'),
      text: '地圖',
      textClass: {
        'map-btn-base-tile-layer-label': true,
      },
    },
  },
  {
    name: 'ImageryMap',
    pane: WebMapPaneConfig.BaseLayer.pane,
    layerType: 'base',
    visible: false,
    url: 'https://mapapi.geodata.gov.hk/gs/api/v1.0.0/xyz/imagery/wgs84/{z}/{x}/{y}.png',
    options: {
      ...BaseTileLayerOptions,
      maxNativeZoom: 19,
    },
    toggleButton: {
      image: require('@/assets/img/btn_imagery_map.png'),
      text: '衛星',
      textClass: {
        'map-btn-base-tile-layer-label': true,
        'white--text': true,
      },
    },
  },
];

export const WMSLayers: WMSLayer[] = [
  {
    type: 'tile',
    name: '路口交通黑點',
    layers: 'HKPC:POI_JUNCTION_BLACKSITE',
    visible: false,
    zIndex: 2,
    options: {
      pane: WebMapPaneConfig.WMSLayer.pane,
      maxZoom: WebMapConfig.maxZoom,
      maxNativeZoom: 21,
    },
  },
  {
    type: 'nontile',
    name: '運輸署智能道路網 (IRN)',
    layers: 'HKPC:IRN_NETWORK',
    visible: false,
    options: {
      layers: 'HKPC:IRN_NETWORK',
      maxZoom: WebMapConfig.maxZoom,
      pane: WebMapPaneConfig.WMSLayer.pane,
      zIndex: 1,
      transparent: true,
      format: 'image/png',
    },
  },
  {
    type: 'tile',
    name: '學校',
    layers: 'HKPC:SCHOOL',
    visible: false,
    zIndex: 1,
    options: {
      pane: WebMapPaneConfig.WMSLayer.pane,
      maxZoom: WebMapConfig.maxZoom,
      maxNativeZoom: 21,
    },
  },
];

export const WebMapColorConfig: MapColorConfig = {
  marker: {
    startLocation: '#4caf50',
    endLocation: '#f44336',
    originalStartLocation: '#2e6930',
    originalEndLocation: '#9e2b23',
    segmentTail: 'black',
    restriction: '#FF5252',
    restrictionPed: '#0091EA',
  },
  path: {
    normal: '#3388ff',
    prohibited: 'grey',
    selected: 'red',
  },
};
