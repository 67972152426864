import Vue from 'vue';
import { Icon } from 'leaflet';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'leaflet-polylinedecorator';
import 'leaflet.nontiledlayer';
// css
import 'typeface-roboto/index.css';
import 'leaflet/dist/leaflet.css';
import './styles/index.scss';
import './styles/map.scss';

Vue.config.productionTip = false;

// For Leaflet Marker Icons
type D = Icon.Default & { _getIconUrl?: string };
delete (Icon.Default.prototype as D)._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
