export const ProjectOptions = [
  { text: 'Chevron雪佛龍', value: 'Chevron' },
  { text: 'DSG特爾高能源', value: 'DSG' },
  { text: 'Exxonmobil埃克森美孚', value: 'Exxonmobil' },
  { text: 'Shell蜆殼', value: 'Shell' },
  { text: 'Sinopec中石化', value: 'Sinopec' },
];

export const ProjectLogoMap = new Map([
  ['Chevron', require('@/assets/img/logos/logo-chevron.png')],
  ['DSG', require('@/assets/img/logos/logo-dsg.png')],
  ['Exxonmobil', require('@/assets/img/logos/logo-exxonmobil.png')],
  ['Shell', require('@/assets/img/logos/logo-shell.png')],
  ['Sinopec', require('@/assets/img/logos/logo-sinopec.png')],
]);

export const ProjectOptionsWithSelectAll = [{ text: '所有項目', value: null }, ...ProjectOptions];

export default ProjectOptions;
