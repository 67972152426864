






import { Component, Prop, Vue } from 'vue-property-decorator';
import { findRealParent, propsBinder, LLayerGroup } from 'vue2-leaflet';
import { nonTiledLayer as LNonTiledLayer, WMSNonTitledLayerOptions, DomEvent } from 'leaflet';
import { PropOptions } from 'vue';

interface ExtendedVuePropOptions extends PropOptions {
  custom?: boolean;
}

interface ExtendedVueProps {
  [x: string]: ExtendedVuePropOptions;
}

interface DomListener {
  [eventName: string]: DomEvent.EventHandlerFn;
}
@Component
export default class Vue2LeafletWMSNonTiledLayer extends Vue {
  // refer to https://github.com/ptv-logistics/Leaflet.NonTiledLayer
  @Prop({ type: String, default: '' }) baseUrl!: string;

  @Prop({ type: Object, default: () => ({}) }) options!: WMSNonTitledLayerOptions;

  @Prop({ type: Boolean, default: true }) visible!: boolean;

  private parentContainer!: LLayerGroup;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private mapObject!: any;

  public ready = false;

  public setVisible(newVal: boolean, oldVal: boolean): void {
    if (newVal === oldVal) return;
    if (newVal) {
      this.parentContainer.addLayer(this);
    } else {
      this.parentContainer.removeLayer(this);
    }
  }

  mounted(): void {
    this.mapObject = LNonTiledLayer.wms(this.baseUrl, this.options);
    DomEvent.on(this.mapObject, this.$listeners as DomListener);
    (this.$options.props as ExtendedVueProps).visible.custom = true;
    propsBinder(this, this.mapObject, this.$options.props as ExtendedVueProps);
    this.ready = true;
    this.parentContainer = findRealParent(this.$parent);
    this.parentContainer.addLayer(this, !this.visible);
  }

  beforeDestroy(): void {
    this.parentContainer.removeLayer(this);
  }
}
