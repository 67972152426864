import { GeoJsonGeometryTypes } from 'geojson';
import { LatLon, LatLong } from '@/types';
import { ConfigItem } from './app';

/**
 * Request
 */
export interface LocationSearchRequest {
  st?: string;
  id?: string;
  mr?: number;
  em?: boolean;
  pj?: string;
}

export interface RouteSearchRequest {
  oid: string;
  did: string;
}

export interface AvoidDto extends LatLon {
  default?: 0 | 1;
}

export interface AvoidLocation extends LatLong {
  isDefault?: boolean;
}

export interface RouteGenerationRequest {
  pairID: number;
  start?: LatLong;
  end?: LatLong;
  avoid?: AvoidLocation[];
  include?: LatLong[];
}

export interface RouteSaveRequest {
  pairID: number;
  result: string;
  irnVersion: string;
  configUsed: string;
  confirm?: number;
}

export interface OriginSuggestionRequest {
  did: string;
  project?: string;
}

export interface DestinationSuggestionRequest {
  oid: string;
  project?: string;
}

export interface RouteAreaSearchRequest {
  coordinates: number[][][];
}

/**
 * Response subtypes
 */
export interface SGSLocation {
  name: string;
  type: string;
  id: string;
  locationId: string;
  project: string;
  inGeom: {
    x: number;
    y: number;
  };
  outGeom: {
    x: number;
    y: number;
  };
}

export const RouteRestrictionTypes = ['ped', 'permit', 'vehicleR', 'vehicleP', 'turnR'] as const;

export type RouteRestrictionType = typeof RouteRestrictionTypes[number];

export interface RouteSegmentInfo {
  id: number;
  desc: string;
  shape: number[];
}

export type RouteSegment = {
  [key in RouteRestrictionType]?: RouteSegmentInfo[];
} & {
  name: string;
  num?: string;
  exit?: string;
  dist: number;
  sDist: string;
  time: string;
  dir: number;
  desc: string;
  remark?: string;
  toll: number;
  tb?: number[];
  ra?: number[];
  jbs?: number[];
  np: number;
  rid: number[][];
  tl?: number[][];
  warn?: string[];
  warnMsg?: {
    id: string;
    desc: string;
    reminder: string;
  }[];
  shape: {
    type: GeoJsonGeometryTypes;
    coordinates: number[][];
  };
};

export interface RouteInformation {
  dist: number;
  sDist: string;
  time: string;
  toll: number;
  risk: number;
  start: { rid: number | string; intersect: number[] };
  end: { rid: number | string; intersect: number[] };
  segment: RouteSegment[];
}

export interface RoutePayload {
  start: LatLon;
  end: LatLon;
  include?: LatLon[];
  avoid?: AvoidDto[];
  vehicle: {
    type: string;
    length?: number;
    width?: number;
    height?: number;
    weight?: number;
    axle_w?: number;
  };
}

export interface RouteResult {
  pairID: number;
  OID: string;
  DID: string;
  payload: RoutePayload;
  route: RouteInformation;
  note?: string[];
}

export interface RouteStatus {
  irnVersion?: string;
  lastUpdateOn?: string;
  confirmedOn?: string;
  configUsed?: ConfigItem[];
}

export interface RouteBasicInfo {
  pairID: number;
  origin: SGSLocation;
  destination: SGSLocation;
  irnVersion: string;
  lastUpdateOn: string;
  confirmedOn?: string;
  errorMessage?: string;
  createdOn: string;
}

/**
 * Response
 */
export interface LocationSearchResponse {
  results: SGSLocation[];
}

export interface RouteErrorResponse {
  pairID: number;
  errorMessage: string;
}

export type RouteResponse = RouteResult & RouteStatus;

export type RouteListResponse = RouteBasicInfo[];

/**
 * Other
 */
export interface RouteListFilter {
  originLocationID: string;
  originName: string;
  originType: string;
  originProject: string;
  destinationLocationID: string;
  destinationName: string;
  destinationType: string;
  irnVersion: string;
  createdOn: string;
  lastUpdateOn: string;
  routeStatus: string;
}
