




import { Component, Prop, Vue } from 'vue-property-decorator';
import { CSSProperties } from '@/types';

@Component
export default class PinMarker extends Vue {
  @Prop() color!: string;

  get pinStyle(): CSSProperties {
    return {
      borderColor: this.color,
    };
  }
}
