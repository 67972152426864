










import { Vue, Component } from 'vue-property-decorator';
import AppHeader from '@/components/app/Header.vue';
import Toast from '@/components/app/Toast.vue';

@Component({
  components: {
    AppHeader,
    Toast,
  },
})
export default class Main extends Vue {}
