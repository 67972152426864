

























import { Vue, Component } from 'vue-property-decorator';
import ConfigDialog from '@/components/app/ConfigDialog.vue';
import EnvLabel from '@/components/app/EnvLabel.vue';
import NavButton from '@/components/app/NavButton.vue';

@Component({
  components: {
    EnvLabel,
    NavButton,
    ConfigDialog,
  },
})
export default class Header extends Vue {
  isShowConfigDialog = false;
}
