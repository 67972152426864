









import { Vue, Component, Prop } from 'vue-property-decorator';
import { Feature, LineString } from 'geojson';
import PopupTemplate from '@/components/map/popups/PopupTemplate.vue';
import ActionButton from '@/components/map/popups/ActionButton.vue';
import { LatLong } from '@/types';
import { routeModule } from '@/store/modules/route';

@Component({
  components: {
    PopupTemplate,
    ActionButton,
  },
})
export default class SegmentPopup extends Vue {
  @Prop({ required: true, type: Object }) feature!: Feature<LineString>;

  @Prop({ required: true, type: Object }) latLong!: LatLong;

  @Prop({ default: false, type: Boolean }) isButtonDisabled!: boolean;

  readonly routeModuleContext = routeModule.context(this.$store);

  get locationName(): string {
    return this.feature.properties?.location || '';
  }

  async addAvoidLocation(): Promise<void> {
    this.routeModuleContext.mutations.addAvoidLocation({ ...this.latLong, isDefault: false });
    await this.routeModuleContext.actions.generateRoute();
  }
}
