





































import { Vue, Component, Prop } from 'vue-property-decorator';
import Lodash from 'lodash';
import { LocationSearchRequest, SGSLocation } from '@/types/route';
import ApiService from '@/services/api';
import { toastModule } from '@/store/modules/toast';

@Component
export default class SearchTextField extends Vue {
  @Prop() value!: SGSLocation | null;

  @Prop() project: string | null | undefined;

  @Prop() id!: string;

  @Prop() defaultSuggestionList!: SGSLocation[];

  $refs!: {
    searchTextFieldRef: Vue & {
      lazyValue: SGSLocation | null;
      reset: () => void;
      focus: () => void;
      blur: () => void;
    };
  };

  readonly toastModuleContext = toastModule.context(this.$store);

  isLoading = false;

  textfieldValue: string | null = null;

  suggestionList = [] as SGSLocation[];

  debouncedFetchSuggestionList = Lodash.debounce(this.fetchSuggestionList, 200);

  get isEmptyTextField(): boolean {
    return this.textfieldValue === null || this.textfieldValue === '';
  }

  focusTextField(): void {
    this.$refs.searchTextFieldRef.focus();
  }

  blurTextField(): void {
    this.$refs.searchTextFieldRef.blur();
  }

  resetTextField(): void {
    this.$nextTick(() => {
      if (this.value) {
        this.$refs.searchTextFieldRef.lazyValue = this.value;
      } else {
        this.$refs.searchTextFieldRef.reset();
      }
    });
  }

  emitLocation(val: SGSLocation | string | null): void {
    if (typeof val !== 'string') {
      this.$emit('input', val);
      this.blurTextField();
    } else {
      this.resetTextField();
    }
  }

  async fetchSuggestionList(val: string): Promise<void> {
    try {
      this.isLoading = true;
      if (val === '' || val === null) {
        ApiService.cancel.LOCATION_SEARCH(this.id);
        this.suggestionList = [];
      } else {
        const query: LocationSearchRequest = { st: val };
        if (this.project) query.pj = this.project;
        const response = await ApiService.searchLocation(query, this.id);
        this.suggestionList = response.results;
      }
    } catch (error) {
      if (typeof error === 'string') {
        this.toastModuleContext.actions.openErrorToast({ message: error });
      }
    } finally {
      this.isLoading = false;
    }
  }
}
