




import { Component, Prop, Vue } from 'vue-property-decorator';
import { CSSProperties } from '@/types';

@Component
export default class CircleMarker extends Vue {
  @Prop() color!: string;

  @Prop() borderColor!: string;

  @Prop() backgroundColor!: string;

  @Prop({ type: Number, default: 6 }) width!: number;

  @Prop({ type: Number, default: 6 }) height!: number;

  get pinStyle(): CSSProperties {
    return {
      'background-color': this.color ?? this.backgroundColor,
      borderColor: this.color ?? this.borderColor,
      width: `${this.width}px`,
      height: `${this.height}px`,
    };
  }
}
