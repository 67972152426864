
























































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { LMarker, LIcon, LPopup, LGeoJson, LFeatureGroup, LTooltip } from 'vue2-leaflet';
import {
  DragEndEvent,
  GeoJSONOptions,
  LatLng as LeafletLatLng,
  LatLngBounds,
  Layer,
  LeafletMouseEvent,
  Map as LeafletMap,
  PopupOptions,
  Symbol as LeafletSymbol,
  TooltipOptions,
} from 'leaflet';
import { FeatureCollection, Feature, LineString, Point } from 'geojson';
import { WebMapPaneConfig, WebMapColorConfig } from '@/configs/map.config';
import { routeModule, routeModuleMapper } from '@/store/modules/route';
import { mapModuleMapper } from '@/store/modules/map';
import { toastModule } from '@/store/modules/toast';
import { CSSProperties, LatLng, LatLong } from '@/types';
import PolylineDecorator from '@/components/leaflet/PolylineDecorator.vue';
import PinMarker from '@/components/map/markers/PinMarker.vue';
import CircleMarker from '@/components/map/markers/CircleMarker.vue';
import RouteRestrictionPopup from '@/components/map/popups/RouteRestrictionPopup.vue';
import SegmentPopup from '@/components/map/popups/SegmentPopup.vue';
import ActionButton from '@/components/map/popups/ActionButton.vue';
import { AvoidLocation, RouteErrorResponse, RouteSegment, SGSLocation } from '@/types/route';
import { GeometryUtils } from '@/utils';
import { MapPaneConfig, MapColorSetting } from '@/types/map';
import vuetify from '@/plugins/vuetify';

enum LocationCoordinateType {
  Start,
  End,
}

@Component({
  components: {
    LMarker,
    LIcon,
    LPopup,
    LGeoJson,
    LTooltip,
    LFeatureGroup,
    PolylineDecorator,
    PinMarker,
    CircleMarker,
    RouteRestrictionPopup,
    SegmentPopup,
    ActionButton,
  },
  computed: {
    ...routeModuleMapper.mapState([
      'isLoading',
      'isSaving',
      'startLocation',
      'endLocation',
      'actualStartLocation',
      'actualEndLocation',
      'avoidLocations',
      'includeLocations',
      'selectedSegmentIndex',
      'routeErrorResponse',
    ]),
    ...routeModuleMapper.mapGetters([
      'routeSegments',
      'routeGeoJSON',
      'routeRestrictionGeoJSON',
      'isConfirmedRoute',
    ]),
    ...mapModuleMapper.mapState(['zoomLevel']),
  },
})
export default class RouteLayer extends Vue {
  @Prop() map!: LeafletMap;

  $refs!: {
    routeFeatureGroupRef: LFeatureGroup;
    routeGeoJsonRef: LGeoJson;
  };

  readonly routeModuleContext = routeModule.context(this.$store);

  readonly toastModuleContext = toastModule.context(this.$store);

  GeometryUtils = GeometryUtils;

  isLoading!: boolean;

  isSaving!: boolean;

  isConfirmedRoute!: boolean;

  routeErrorResponse!: RouteErrorResponse | null;

  startLocation!: SGSLocation | null;

  endLocation!: SGSLocation | null;

  actualStartLocation!: LatLong | null;

  actualEndLocation!: LatLong | null;

  avoidLocations!: LatLong[];

  includeLocations!: LatLong[];

  selectedSegmentIndex!: number | null;

  routeSegments!: RouteSegment[] | null;

  routeGeoJSON!: FeatureCollection<LineString>;

  routeRestrictionGeoJSON!: FeatureCollection<Point>;

  zoomLevel!: number;

  mapPaneConfig: MapPaneConfig = WebMapPaneConfig;

  markerColorConfig: MapColorSetting = WebMapColorConfig.marker;

  pathColorConfig: MapColorSetting = WebMapColorConfig.path;

  pinMarkerIconAnchor: number[] = [12, 35];

  pinMarkerPopupAnchor: number[] = [0, -33];

  circleMarkerIconAnchor: number[] = [5, 5];

  restrictionIconAnchor: number[] = [6, 6];

  restrictionPopupAnchor: number[] = [0, -4];

  avoidLocationIconAnchor: number[] = [8, 12];

  includeLocationIconAnchor: number[] = [7, 12];

  trafficSignIconAnchor: number[] = [12, 10];

  avoidTooltipOptions: TooltipOptions = {
    className: 'default-avoid-banner',
  };

  routeArrowHeadStyle = {
    patterns: [
      {
        offset: 10,
        repeat: 100,
        symbol: LeafletSymbol.arrowHead({
          pixelSize: 10,
          headAngle: 40,
          pathOptions: {
            stroke: true,
            fillOpacity: 1,
            weight: 0,
            color: '#fff',
            pane: this.mapPaneConfig.RouteArrow.pane,
          },
        }),
      },
    ],
  };

  locationPopupOptions: PopupOptions = {
    closeButton: false,
    maxWidth: 300,
    minWidth: 220,
    className: 'map-route-location-popup',
    pane: WebMapPaneConfig.MapPopup.pane,
  };

  restrictionPopupOptions: PopupOptions = {
    closeButton: true,
    minWidth: 300,
    className: 'map-route-restriction-popup',
    pane: WebMapPaneConfig.MapPopup.pane,
  };

  routePathOptions: GeoJSONOptions = {
    onEachFeature: (feature: Feature<LineString>, layer: Layer) => {
      let popup: SegmentPopup;
      layer.on({
        click: (event: LeafletMouseEvent) => {
          const { latlng } = event;
          const latLong = { lat: latlng.lat, long: latlng.lng };
          popup = this.createSegmentPopup(feature, latLong);
          const popupElement = popup.$mount().$el as HTMLElement;
          layer.bindPopup(popupElement, this.locationPopupOptions);
          layer.openPopup(latlng);
        },
        popupopen: () => {
          this.updateSelectedSegment(feature.properties?.segmentIndex);
        },
        popupclose: () => {
          popup.$destroy();
          this.updateSelectedSegment(null);
        },
      });
    },
  };

  get isFreezeMap(): boolean {
    return this.isSaving;
  }

  get isNarrowZoom(): boolean {
    return this.zoomLevel > 15;
  }

  get routeArrowPath(): LatLng[] {
    return this.routeGeoJSON.features.flatMap((f) =>
      f.geometry.coordinates.map((c) => GeometryUtils.coordsToLatLng(c)),
    );
  }

  get segmentTailLocation(): LatLng[] {
    const locations = this.routeGeoJSON.features.map(
      (f) => f.geometry.coordinates.map((c) => GeometryUtils.coordsToLatLng(c)).slice(-1)[0],
    );
    return locations.slice(0, -1);
  }

  get roundaboutLocation(): LatLng[] {
    if (this.routeSegments) {
      return this.routeSegments.flatMap((s) => {
        return s.ra ? GeometryUtils.wrapLatLng(s.ra) : [];
      });
    }
    return [];
  }

  get blackspotLocation(): LatLng[] {
    if (this.routeSegments) {
      return this.routeSegments.flatMap((s) => {
        return s.jbs ? GeometryUtils.wrapLatLng(s.jbs) : [];
      });
    }
    return [];
  }

  get trafficLightLocation(): LatLng[] {
    if (this.routeSegments) {
      return this.routeSegments.flatMap((s) => {
        return s.tl ? s.tl.flatMap((t) => GeometryUtils.wrapLatLng(t)) : [];
      });
    }
    return [];
  }

  get isAlteredStartLocation(): boolean {
    if (this.startLocation && this.actualStartLocation) {
      const isSameLat = this.startLocation.outGeom.y === this.actualStartLocation.lat;
      const isSameLng = this.startLocation.outGeom.x === this.actualStartLocation.long;
      return !(isSameLat && isSameLng);
    }
    return false;
  }

  get isAlteredEndLocation(): boolean {
    if (this.endLocation && this.actualEndLocation) {
      const isSameLat = this.endLocation.inGeom.y === this.actualEndLocation.lat;
      const isSameLng = this.endLocation.inGeom.x === this.actualEndLocation.long;
      return !(isSameLat && isSameLng);
    }
    return false;
  }

  createSegmentPopup(feature: Feature<LineString>, latLong: LatLong): SegmentPopup {
    return new SegmentPopup({
      store: this.$store,
      vuetify,
      propsData: { feature, latLong, isButtonDisabled: this.isFreezeMap },
    });
  }

  getRoutePathStyle(feature: Feature<LineString>): CSSProperties {
    const isProhibited = feature.properties?.isProhibited;
    const isSelected = feature.properties?.segmentIndex === this.selectedSegmentIndex;
    const style: CSSProperties = {
      weight: this.isNarrowZoom ? 6 : 4,
      opacity: 1,
      color: this.getRoutePathColor(isProhibited, isSelected),
    };
    return style;
  }

  getRoutePathColor(isProhibited: boolean, isSelected: boolean): string {
    let color = this.pathColorConfig.normal;
    if (isSelected) color = this.pathColorConfig.selected;
    else if (isProhibited) color = this.pathColorConfig.prohibited;
    return color;
  }

  updateSelectedSegment(segmentIndex: number | null): void {
    this.routeModuleContext.mutations.updateSelectedSegmentIndex(segmentIndex);
  }

  async resetStartLocation(): Promise<void> {
    this.routeModuleContext.mutations.updateActualStartLocation(null);
    await this.routeModuleContext.actions.generateRoute();
  }

  async resetEndLocation(): Promise<void> {
    this.routeModuleContext.mutations.updateActualEndLocation(null);
    await this.routeModuleContext.actions.generateRoute();
  }

  async removeAvoidLocation(location: AvoidLocation): Promise<void> {
    this.routeModuleContext.mutations.removeAvoidLocation(location);
    await this.routeModuleContext.actions.generateRoute();
  }

  async removeIncludeLocation(location: LatLong): Promise<void> {
    this.routeModuleContext.mutations.removeIncludeLocation(location);
    await this.routeModuleContext.actions.generateRoute();
  }

  getDragEndlatLong(event: DragEndEvent): LatLong {
    const latlng = event.target.getLatLng();
    const latLong = { lat: latlng.lat, long: latlng.lng };
    return latLong;
  }

  async onSegmentTailDragEnd(event: DragEndEvent): Promise<void> {
    const latLong = this.getDragEndlatLong(event);
    this.routeModuleContext.mutations.addIncludeLocation(latLong);
    await this.routeModuleContext.actions.generateRoute();
  }

  async onAvoidMarkerDragEnd(event: DragEndEvent, index: number): Promise<void> {
    const latLong = this.getDragEndlatLong(event);
    this.routeModuleContext.mutations.updateAvoidLocation({
      index,
      location: { ...latLong, isDefault: false },
    });
    await this.routeModuleContext.actions.generateRoute();
  }

  async onIncludeMarkerDragEnd(event: DragEndEvent, index: number): Promise<void> {
    const latLong = this.getDragEndlatLong(event);
    this.routeModuleContext.mutations.updateIncludeLocation({ index, location: latLong });
    await this.routeModuleContext.actions.generateRoute();
  }

  async onActualStartLocationDragEnd(event: DragEndEvent): Promise<void> {
    const latLong = this.getDragEndlatLong(event);
    const distance = this.getActualLocationDistance(
      event.target.getLatLng(),
      this.startLocation!,
      LocationCoordinateType.Start,
    );
    if (distance > 500) {
      this.toastModuleContext.actions.openToast({
        color: 'warning',
        message: `注意︰新起點距離原始位置約 ${distance.toFixed()} 米`,
      });
    }
    this.routeModuleContext.mutations.updateActualStartLocation(latLong);
    await this.routeModuleContext.actions.generateRoute();
  }

  async onActualEndLocationDragEnd(event: DragEndEvent): Promise<void> {
    const latLong = this.getDragEndlatLong(event);
    const distance = this.getActualLocationDistance(
      event.target.getLatLng(),
      this.endLocation!,
      LocationCoordinateType.End,
    );
    if (distance > 500) {
      this.toastModuleContext.actions.openToast({
        color: 'warning',
        message: `注意︰新終點距離原始位置約 ${distance.toFixed()} 米`,
      });
    }
    this.routeModuleContext.mutations.updateActualEndLocation(latLong);
    await this.routeModuleContext.actions.generateRoute();
  }

  getActualLocationDistance(
    newLocation: LeafletLatLng,
    originalLocation: SGSLocation,
    type: LocationCoordinateType,
  ): number {
    const lat =
      type === LocationCoordinateType.Start
        ? originalLocation.outGeom.y
        : originalLocation.inGeom.y;
    const lng =
      type === LocationCoordinateType.Start
        ? originalLocation.outGeom.x
        : originalLocation.inGeom.x;
    return newLocation.distanceTo({ lat, lng });
  }

  onSGSLocationChange(sgsLocation: SGSLocation | null, type: LocationCoordinateType): void {
    const location =
      type === LocationCoordinateType.Start ? sgsLocation?.inGeom : sgsLocation?.outGeom;
    if (location) {
      this.$nextTick(() => {
        this.map.panTo([location.y, location.x]);
      });
    }
    this.routeModuleContext.actions.clearRoute();
  }

  @Watch('routeGeoJSON', { deep: true })
  onRouteChange(val: FeatureCollection<LineString>): void {
    if (val.features.length !== 0) {
      this.$nextTick(() => {
        const bounds = this.$refs.routeFeatureGroupRef.mapObject.getBounds();
        if (bounds.isValid()) {
          this.map.flyToBounds(bounds, { animate: false, padding: [100, 100] });
        }
      });
    }
  }

  @Watch('startLocation')
  onStartLocationChange(val: SGSLocation | null): void {
    this.onSGSLocationChange(val, LocationCoordinateType.Start);
  }

  @Watch('endLocation')
  onEndLocationChange(val: SGSLocation | null): void {
    this.onSGSLocationChange(val, LocationCoordinateType.End);
  }

  @Watch('isNarrowZoom')
  onIsNarrowZoomChange(): void {
    this.$refs.routeGeoJsonRef?.setOptionsStyle(this.getRoutePathStyle);
  }

  @Watch('selectedSegmentIndex')
  onSelectedSegmentIndexChange(val: number | null): void {
    // Update route path style (highlight selected segment)
    this.$refs.routeGeoJsonRef?.setOptionsStyle(this.getRoutePathStyle);
    // Pan to segment
    if (val !== null) {
      this.$nextTick(() => {
        const { coordinates } = this.routeGeoJSON.features[val].geometry;
        const latLngTuples = coordinates.map((c) => GeometryUtils.coordsToLatLngTuple(c));
        const bounds = new LatLngBounds(latLngTuples);
        this.map.panInsideBounds(bounds);
      });
    }
  }

  @Watch('isSaving')
  onSavingStateChange(): void {
    this.map.closePopup();
  }

  @Watch('routeErrorResponse')
  onRouteErrorResponseChange(val: string | null): void {
    if (val !== null) {
      this.$nextTick(() => {
        const bounds = this.$refs.routeFeatureGroupRef.mapObject.getBounds();
        if (bounds.isValid()) {
          this.map.flyToBounds(bounds, { animate: false, padding: [100, 100], maxZoom: 18 });
        }
      });
    }
  }
}
