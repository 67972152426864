import { RouteRestrictionType } from '@/types/route';

export const RouteDirectionTypes: Map<number, string> = new Map([
  [1, 'fas fa-arrow-circle-left'],
  [2, 'fas fa-arrow-circle-right'],
  [3, 'fas fa-arrow-circle-up'],
  [4, 'fas fa-arrow-circle-down'],
]);

export const RouteRestrictionDescription: Map<RouteRestrictionType, string> = new Map([
  ['ped', '行人專用區'],
  ['permit', '許可證路段'],
  ['vehicleR', '車輛限制'],
  ['vehicleP', '車輛禁區'],
  ['turnR', '路口轉向限制'],
]);

export enum RouteStatusType {
  Confirmed = '已確認',
  Pending = '等待確認',
  Error = '錯誤',
}

export const RouteStatusColor: { [key in RouteStatusType]: string } = {
  [RouteStatusType.Confirmed]: '#008000',
  [RouteStatusType.Pending]: '#993300',
  [RouteStatusType.Error]: '#ff0000',
};
